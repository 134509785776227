import React, {useState} from 'react';
import Image from "../../../../assets/img/Image";
import MissionCard from "./MissionCard";
function Mission(props) {
    const {items, title , description} = props

    return (
        <div>
            <div className="mt-sm-5 mt-0">
                <div className="page-head">
                    <p>{title}</p>
                </div>
                <div className="container-fluid mx-md-3 px-md-5 mx-2 px-0">
                    <div className="my-3 w-100">
                        <p>
                            {description}
                        </p>
                    </div>
                    <div className="row justify-content-center mission-content animated zoomIn">
                        {items && items.map((data , index)=>{
                             return <div key={index} className={`${index > 1 ? 'col-md-4' : 'col-md-6' } mb-3 col-sm-6`} >
                                <MissionCard
                                    index={index}
                                    image={data.icon.path}
                                    content={data.locale.description}
                                    name={data.locale.title}
                                />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Mission;
