import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import {connect} from "react-redux";
import {getAbout} from "../../../redux/actions";
import {useTranslation} from "react-i18next";


function AboutContent(props) {
    const {t} = useTranslation()
    const {about , getAbout} = props
    const trigger = 1
    useEffect(()=>{
           getAbout()
    },[trigger , t])


    return (
        <div className="main">
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="mt-5 pt-5 about-content">
                        <div className="d-flex justify-content-between align-items-center">
                            <Link to="/about" className="about-back">
                                <img src={Image.Back} alt=""/>
                                <p>{t('regions.regionsBack')}</p>
                            </Link>
                            <div className="d-flex page-head p-0">
                                <p>{t('aboutFund.title')}</p>
                            </div>
                        </div>
                        <div className="img ">
                            <img  src={about?.picture?.path} alt=""/>
                        </div>
                        <div className="aboutcontent-text">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: about.locale?.about
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ about  }) => {
    return {about};
};

export default connect(mapStateToProps, { getAbout  })(AboutContent);

