import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import sif from "../../../../const/api";
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import './index.css'
function AboutFund(props) {
    const [structures, setStructures] = useState([]);
    const {t} = useTranslation();
    const [data, setD] = useState([]);
    useEffect(()=>{
        sif.get('company/structures').then((res)=>{
            res.data.content.length  && setStructures(res.data.content[0])
            createData(res.data.content[0])
        }).catch((err)=>{
            console.log(err)
        })
    } ,[t])


    const createData = (data)  =>{
        let obj = {
            ...data,
            id:data?.id,
            value:data?.id,
            parent_id:data?.parent_id,
            title:data?.locale.name,
            children:data?.children
        }
        data?.children && data.children.forEach((s, i)=>{
            obj.children[i] = {
                value:s.id,
                id:s.id,
                parent_id:s.parent_id,
                title:s.locale.name,
                children:s.children
            }
            createData(s)
        })
        setD([obj])
    }

    const MyNodeComponent = ({node}) => {
        return (
            <div className="initechNode">
                <div className={'w-100 h-100 d-flex justify-content-center align-items-center'}> {node.title ? node?.title : node?.locale?.name}</div>
            </div>
        );
    };

     return (
        <div className="mt-5">
            <div className="page-head">
                <p>{t('fundStructure.title')}</p>
            </div>
            <div className="fund container-fluid">
                <div className="row">
                    <div className="col-12">
                        <OrgChart  className={'animated mt-2 fadeInUp w-100'}  tree={structures} NodeComponent={MyNodeComponent} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutFund;
