import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import {connect} from "react-redux";
import {getInstructions} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";


function Instructions(props) {
    const {t , i18n} = useTranslation();
    const { getInstructions, instructions } = props
    useEffect(()=>{
        if (instructions.length === 0 || i18n.language !== props.lang){
            getInstructions()
        }
    },[t])
    return (
       <div className="main">
           <Helmet>
               <title>{t('site.EDF')}  - {t('instructions.instructionsTitle').toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}</title>
           </Helmet>
           <div className="position-relative">
               <div className="row pt-5 mt-5">
                   <div className="col-12">
                       <div className="page-head pl-sm-0 pl-3">
                           <p>{t('instructions.instructionsTitle')}</p>
                       </div>
                       {/*<Link to="/" className="about-back mt-lg-4 mt-md-4 mt-sm-5 mt-5 pt-lg-0 pt-md-0 pt-sm-2 pt-3">*/}
                       {/*    <img src={Image.Back} alt=""/>*/}
                       {/*</Link>*/}

                   </div>

                   <div className="legislation-content w-100">
                       <div className="row  video-apply-text">
                           {instructions?.length > 0 ?
                               instructions?.map((instructions, index) => (
                                   <a target={"blank"} key={index} href={instructions.link} className="col-lg-6 d-block text-decoration-none text-dark col-md-6 col-sm-12 col-11 mx-0 mb-3 mt-3">
                                       <div className="d-flex instruction-link align-items-center">
                                           <p className="mr-3">{index + 1}</p>
                                           <span>{instructions.locale.name}</span>
                                           <a>
                                               <img src={Image.Link} alt="" className="link"/>
                                           </a>
                                       </div>
                                   </a>
                               )) : null
                           }
                       </div>
                   </div>
               </div>
               <div>
                   <img src={Image.Map} alt="" className="bg-image"/>
               </div>
           </div>
       </div>
    );
}

const mapStateToProps = ({ instructions , lang }) => {
    return { instructions , lang };
}

export default connect(mapStateToProps, { getInstructions  })(Instructions);

