import React from 'react';
import {Link} from "react-router-dom";
import Image from "../../../../assets/img/Image";
import Form from "react-bootstrap/Form";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

function Calculator(props) {
    // const {id} = props.match?.params
    const {t} = useTranslation();
    return (
        <div className="main">
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row animated fadeIn">
                        <div className="col-lg-6 col-md-6 font-weight-bold col-sm-12 col-12 mt-md-0 mt-lg-3">
                            <div>
                                <h1 className={'text-bold font-weight-bold'}>{props.locations}</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="credit-about">
                                <p>{t('calculator.calculatorTitle')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="calculator  animated fadeInUp col-lg-9 col-md-12 col-sm-12 col-12 mt-5 p-0">
                        <div className="col-12 p-0">
                            <p>{t('calculator.creditDate')}</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-11 col-11">
                            <span>{t('calculator.year')}</span>
                                <p>{t('calculator.creditYear')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range" />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-11 col-11 ">
                            <span>{t('calculator.month')}</span>
                                <p>{t('calculator.creditMonth')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range" />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-11 col-11">
                            <span>{t('calculator.creditAmountTitle')}</span>
                                <p>{t('calculator.creditAmount')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range" />
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>

                        <div className="col-12 mt-lg-2 mt-lg-2 mt-md-3 mt-sm-5 mt-5 p-0">
                            <p>{t('calculator.creditDurationTitle')}</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col-sm-11 col-11">
                            <span>{t('calculator.year')}</span>
                                <p>{t('calculator.creditDurationYear')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range"/>
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-11">
                            <span>{t('calculator.month')}</span>
                                <p>{t('calculator.creditDurationMonth')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range" />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-11">
                            <span>{t('calculator.year')}</span>
                                <p>{t('calculator.creditDurationLastYear')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range" />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-11">
                            <span>{t('calculator.month')}</span>
                                <p>{t('calculator.creditDurationLastMonth')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range" />
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>

                        <div className="col-12 mt-lg-2 mt-md-3 mt-sm-5 mt-5 p-0">
                            <p>{t('calculator.creditPercent')}</p>
                        </div>
                        <div className="col-12 row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12 p-0">
                                <p>{t('calculator.creditPercentNumber')}</p>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Control type="range" />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className="d-flex col-lg-9 col-md-6 col-sm-12 col-12 justify-content-lg-end align-items-center mt-lg-0 mt-md-0 mt-sm-5 mt-5 p-0">
                                <div className="transition">
                                    <Link to="/" className="d-flex line align-items-center">
                                        <p>{t('calculator.creditCalculate')}</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.066" height="12" viewBox="0 0 17.066 12"><path d="M11.492,4.854a.605.605,0,0,0-.86.851l4.361,4.361H.6a.6.6,0,0,0-.6.6.606.606,0,0,0,.6.611H14.993l-4.361,4.353a.617.617,0,0,0,0,.86.6.6,0,0,0,.86,0L16.886,11.1a.592.592,0,0,0,0-.851Z" transform="translate(0 -4.674)" fill="black"/></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ locations }) => {
    return { locations };
}

export default connect(mapStateToProps)(Calculator);
