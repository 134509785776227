import React , {useState , useEffect} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { withTranslation } from "react-i18next";
import {connect, useDispatch, useSelector} from "react-redux";
import {changeLanguage, getLanguages} from "../../../redux/actions";

const  Lang = (props) => {
    const [lang, setLang] = useState("az");
    let langs = useSelector(s => s.langs);
    let dispatch = useDispatch()
    let newLang = localStorage.getItem('locale')
    const changeLang = (lang) => {
        const { i18n } = props;
        localStorage.setItem("locale", lang);
        i18n.changeLanguage(lang);
        setLang(lang);
        // for creating new request
       setTimeout(()=>{
          props.changeLanguage(lang);
       } , 10)
        // window.location.reload()
    };

    useEffect(() => {
        setLang(
            localStorage.getItem("locale") ? localStorage.getItem("locale") : "az"
        );
        if(langs.length <= 0) {
            dispatch(getLanguages())
        }
    } , [lang])

    return (
        <div className="lang ml-2">
            <Dropdown>
                <Dropdown.Toggle className='text-white'  variant='text' id="dropdown-basic">
                    <p>{lang.toUpperCase()}</p>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {langs.length > 0 && langs?.map((l  ,i)=>(
                        <>
                            {newLang !== l.key &&
                              <Dropdown.Item onClick={() => {changeLang(l.key)}} className={'animated fadeIn'}><p>{l.key.toUpperCase()}</p></Dropdown.Item>
                            }
                        </>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default connect(null, { changeLanguage })(withTranslation()(Lang));

