export default {

TOGGLE: 'TOGGLE',
SET_MAP: 'SET_MAP',
SEARCH_SUCCESS: 'SEARCH_SUCCESS',
SEARCH_ERROR: 'SEARCH_ERROR',
GET_PRODUCT: 'GET_PRODUCT',
GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',
GET_LEGISTATION: 'GET_LEGISTATION',
GET_LEGISTATION_ERROR: 'GET_LEGISTATION_ERROR',
GET_ROAD_MAP: 'GET_ROAD_MAP',
GET_ROAD_MAP_ERROR: 'GET_ROAD_MAP_ERROR',
SET_LANG: 'SET_LANG',
GET_LANG: 'GET_LANG',
GET_LANGS: 'GET_LANGS',
LOADING_ON: 'LOADING_ON',
LOADING_OFF: 'LOADING_OFF',
GET_LANGS_ERROR: 'GET_LANGS_ERROR',
LOCATION_SUCCESS: 'LOCATION_SUCCESS',
GET_ABOUT: 'GET_ABOUT',
GET_ABOUT_ERROR: 'GET_ABOUT_ERROR',
GET_CATEGORIES: 'GET_CATEGORIES',
GET_CATEGORIES_ERROR: 'GET_CATEGORIES_ERROR',
GET_CONTACT: 'GET_CONTACT',
GET_CONTACT_ERROR: 'GET_CONTACT_ERROR',
GET_TERMS: 'GET_TERMS',
GET_TERMS_ERROR: 'GET_TERMS_ERROR',
GET_STATISTICS: 'GET_STATISTICS',
GET_STATISTICS_ERROR: 'GET_STATISTICS_ERROR',
GET_DIRECTORIES: 'GET_DIRECTORIES',
GET_DIRECTORIES_ERROR: 'GET_DIRECTORIES_ERROR',

GET_GALLERY: 'GET_GALLERY',
GET_GALLERY_ERROR: 'GET_GALLERY_ERROR',

GET_POSTS: 'GET_POSTS',
GET_POSTS_ERROR: 'GET_POSTS_ERROR',

GET_BANKS: 'GET_BANKS',
GET_BANKS_ERROR: 'GET_BANKS_ERROR',

GET_CUSTOMERS: 'GET_CUSTOMER',
GET_CUSTOMERS_ERROR: 'GET_CUSTOMERS_ERROR',

GET_BANKS_BRANCHES: 'GET_BANKS_BRANCHES',
GET_BANKS_BRANCHES_ERROR: 'GET_BANKS_BRANCHES_ERROR',

GET_INSTRUCTIONS: 'GET_INSTRUCTIONS',
GET_INSTRUCTIONS_ERROR: 'GET_INSTRUCTIONS_ERROR',

GET_FAQS: 'GET_FAQS',
GET_FAQS_ERROR: 'GET_FAQS_ERROR',

GET_PROJECTS: 'GET_PROJECTS',
GET_PROJECTS_ERROR: 'GET_PROJECTS_ERROR',

GET_PARTNERS: 'GET_PARTNERS',
GET_PARTNERS_ERROR: 'GET_PARTNERS_ERROR',

GET_PARTNERPAGE: 'GET_PARTNERPAGE',
GET_PARTNERPAGE_ERROR: 'GET_PARTNERPAGE_ERROR',

GET_REPORTS: 'GET_REPORTS',
GET_REPORTS_ERROR: 'GET_REPORTS_ERROR',

GET_REPORT_ITEMS: 'GET_REPORT_ITEMS',
GET_REPORT_ITEMS_ERROR: 'GET_REPORT_ITEMS_ERROR',

GET_MISSION: 'GET_MISSION',
GET_MISSION_ERROR: 'GET_MISSION_ERROR',
HOVER: 'HOVER',
OUT: 'OUT',
ACCEPT: 'ACCEPT',
NOTACCEPT: 'NOTACCEPT',

}
