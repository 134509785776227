import React from 'react';
import Image from "../../../assets/img/Image";
import {Link} from "react-router-dom";
import Lang from "../../Elements/Lang/Lang";
import {connect} from "react-redux";
import {toggleMenu} from "../../../redux/actions";
import Search from "../../Elements/Search/Search";
import {useTranslation} from "react-i18next";

function Header(props) {
    const {t} = useTranslation()
    const {toggleMenu} = props;
    return (
        <div className="container-fluid header">
            <div className="m-0 d-flex align-items-center justify-content-between">
                <div onClick={() => { props.toggleReducer && toggleMenu()}}>
                   <Link to="/">
                       <img src={Image.Logo} alt="" className="logo pr-3"/>
                   </Link>
                </div>
                <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-center align-items-center header-links mb-lg-0 mb-md-0 mb-sm-3 mb-0">
                    <div className="d-flex align-items-center line-right">
                        <Search/>
                        <Lang/>
                    </div>
                    <div>
                        {props.toggleReducer ?
                            <div onClick={toggleMenu} className="d-flex">
                                <span className="menu">{t('menu.close')}</span>
                                <img src={Image.Close} alt=""/>
                            </div>:
                            <div onClick={toggleMenu} className="d-flex">
                                <span className="menu">{t('menu.menu')}</span>
                                <img src={Image.OpenMenu} alt=""/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({toggleReducer}) => {
    return {toggleReducer}
}
export default connect(mapStateToProps , {toggleMenu})(Header);

