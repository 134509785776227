import React, {useState , useEffect} from 'react';
import Image from "../../../../assets/img/Image";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

function Priority(props) {
    const [priorities , setPriorities] = useState([])
    const [documents , setDocuments] = useState([])
    const [note , setNote] = useState('')
    const [active , setActive] = useState(undefined)
    const {t} = useTranslation();
    const {postList, id , locations} = props;
    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    useEffect(() => {
        priorities.length > 0 && setNote(priorities[0]?.locale?.note && priorities[0]?.locale?.note);
    },[priorities])

    useEffect(() =>{
        if (postList.length > 0){
            let data =  postList.filter(f => f.is_active === 1)
            setPriorities(data)
        }
    },[postList])

    const onChange = (priority) => {
      priority?.files.length > 0 && setDocuments(priority?.files?.filter(f => f.is_active === 1))
      setActive(priority?.id)
      setNote(priority?.locale?.note)
    }

    return (
        <div className="row animated fadeIn">
            <div className="col-lg-5 col-md-10 col-sm-10 col-10 head">
                <div>
                    <h1>{locations}</h1>
                </div>
                <div className="priority">
                    <p>{t('priority.priorityText')}</p>
                </div>
                <div className="priority-text mb-0">
                    {priorities && priorities?.map((l,index)=>(
                      <React.Fragment key={index}>
                          <div onClick={()=>{onChange(l)}} key={index} className="d-flex cursor-pointer align-items-center">
                              <p className={`${l.id === active ? 'active': ' '} numb`}>{pad(index + 1)}</p>
                              <span
                                  className={'d-block'}
                                  dangerouslySetInnerHTML={{
                                      __html: l.locale?.name
                                  }}
                              />
                          </div>
                          {l.id === active &&
                              <>
                                  {l?.files?.length > 0 &&
                                  <div className="d-flex animated fadeIn  mt-0 mb-2 col-12 overflow-y align-items-baseline document-note">
                                      {documents?.map((l, i)=>(
                                          <a className={'text-white nav-link'} href={l?.file?.path} target={'_blank'}>
                                              <p className={'note mr-0 d-flex px-3 py-2 nav-link align-items-center text-decoration-none flex-between'}>
                                                  <span className={'text-white mr-2'} > {l?.locale?.name}</span>
                                                  <img style={{height:'20px'}} src={Image.Download} alt=""/>
                                              </p>
                                          </a>
                                      ))}
                                  </div>
                                  }
                              </>
                          }
                      </React.Fragment>

                    ))}
                </div>
            </div>
            <div className="col-lg-7 pr-5 col-md-6 col-sm-6 col-6">
                <div className="credit-about">
                    <p>{t('priority.priorityTitle')}</p>
                </div>
                <div>
                    <img src={Image.Girl} alt="" className="girl" />
                </div>
            </div>



            {
                note?.length > 1 &&
                <div className="d-flex align-items-baseline document-note">
                    <p className={'note'}>{t('documents.documentsNote')}</p>
                    <span
                        dangerouslySetInnerHTML={{
                            __html:note
                        }}
                    />
                </div>
            }

        </div>

    );
}


const mapStateToProps = ({ product  ,locations}) => {
    return { product ,locations };
};

export default connect(mapStateToProps)(Priority);

