import React, {useEffect, useRef} from 'react';
import Slider from "react-slick";
import {connect} from "react-redux";
import {getDirectories} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";

function Leadership(props) {
    const {getDirectories , directory} = props;
    const {t , i18n} = useTranslation();
    const next = () => {
        leader.current.slickNext();
    }
    const previous = () => {
        leader.current.slickPrev();
    }


    useEffect(()=>{
        let mounted = true
        if (mounted){
          if (!directory.length || i18n.language !== props.lang) {
              getDirectories()
          }
        }
        return function cleanup() {
            mounted = false
        }
    }, [t])

    const leader = useRef();

    const leadsets = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 500,
        autoplaySpeed: 200000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows : false,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };



    return (
        <div>
            <div className="row  mt-5">
                <div className="col-11 d-flex align-items-center justify-content-between">
                    <div className="page-head">
                        <p>{t('leadership.title')}</p>
                    </div>
                        {directory?.length >= 4 &&
                        <div className="arrows d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start p-0">
                            <div className={'mr-2'} onClick={() => {previous()}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                            </div>
                            <div onClick={() => {next()}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                            </div>
                        </div>
                    }
                </div>

            </div>
            {directory?.length <= 3 ?
                <div className="d-sm-flex d-block justify-content-center mt-2">
                    {directory.length > 0 ? directory.map((l, i)=>{
                        return   <div key={i} className="leadership-img position-relative mr-4 mb-md-0 mb-4">
                            <img src={l.picture?.path} alt=""/>
                            <div className="leadership-img-text">
                                <strong>{l.locale?.name}</strong>
                                <p>{l?.locale?.position}</p>
                            </div>
                        </div>
                    }) : null }
                </div> :
                <div>
                    <Slider
                        className='mainslider px-5 mx-4 mt-2'
                        ref={slider => (leader.current = slider)}
                        {...leadsets}
                    >
                        {directory.length > 0 ? directory?.map((l, i)=>{
                            return   <div key={i} className="px-2 leadership-img position-relative ">
                                <img src={l.picture?.path} alt=""/>
                                <div className="leadership-img-text">
                                    <strong>{l.locale?.name}</strong>
                                    <p>{l.locale.position}</p>
                                </div>
                            </div>
                        }) :  null  }
                    </Slider>
                </div>
            }
        </div>

    );
}



const mapStateToProps = ({ directory,  lang}) => {
    return {directory, lang};
};

export default connect(mapStateToProps, { getDirectories })(Leadership);
