import React , {useEffect , useState} from 'react';
import Image from "../../../../assets/img/Image";
import {connect} from "react-redux";
import {getLegistation} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

const CreditClassification = (props) => {
    const {t ,i18n} = useTranslation()
    const {legistation , getLegistation} = props;
    useEffect(()=>{
        let mounted = true
       if (mounted){
           if (!legistation.length  || i18n.language !== props.lang){
               getLegistation()
           }
       }
        return function cleanup() {
            mounted = false
        }
    } , [t])

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    return (
        <div className="row pt-4 mt-lg-0 mt-md-0 mt-sm-5 mt-5">
            <Helmet>
                <title>{t('site.EDF')}  - {props.id === 5 ? t('menu.roadMap').toLocaleUpperCase(localStorage.getItem('locale').toUpperCase()) : t('menu.legistation').toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}</title>
            </Helmet>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="page-head p-0">
                    {props.id === 5 ?
                    <p>{t('legistation.question')}</p>
                    :
                    <p>{props.name}</p>
                    }
                </div>
            </div>
            <Link to="/" className="about-back mt-lg-4 mt-md-4 mt-sm-5 mt-5 pt-lg-0 pt-md-0 pt-sm-2 pt-3">
                <img src={Image.Back} alt=""/>
            </Link>
            <div className="legislation-content w-100 animated fadeIn">
                {
                    props.id === 5 &&
                    <div>
                        <p>
                            {t('legistation.legistationText')}
                        </p>
                    </div>
                }
                <div className="row video-apply-text ">
                    {legistation.length > 0 ? legistation
                        ?.filter((i)=>{return  i.position === props.id})
                        .map((l,i)=>(
                        <a  href={l.link} target={'_blank'} key={i} className="col-lg-6 d-block text-decoration-none text-dark col-md-6 col-sm-12 col-12 mx-0  mb-3 mt-3">
                            <div className="d-flex legislation-link align-items-center">
                                <p>{pad(i+1)}</p>
                                <span>{l.locale.name}</span>
                                <a>
                                    <img src={Image.Link} alt="" className="link"/>
                                </a>
                            </div>
                        </a>
                    )) : null}
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ legistation  , location , lang}) => {
    return { legistation  , location , lang};
};

export default connect(mapStateToProps, { getLegistation })(CreditClassification);

