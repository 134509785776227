import React, {useContext, useEffect, useState , useCallback} from "react";
import { Switch, Route, __RouterContext } from "react-router";
import { useTransition, animated } from "react-spring";
import About from "../../Pages/About/About";
import AboutContent from "../../Pages/AboutContent/AboutContent";
import CreditContent from "../../Pages/CreditContent/CreditContent";
import CreditCalculator from "../../Pages/CreditContent/CreditCalculator/CreditCalculator";
import Home from "../../Pages/Home/Home";
import Credit from "../../Pages/Credit/Credit";
import Products from "../../Pages/Products/Products";
import ClientHistory from "../../Pages/ClientHistory/ClientHistory";
import Gallery from "../../Pages/Gallery/Gallery";
import FullPost from "../../Pages/newsAndAnnouncement/FullPost";
import Full from "../../Pages/Gallery/Full";
import CreditOrganization from "../../Pages/CreditOrganization/CreditOrganization";
import RoadMap from "../../Pages/RoadMap/RoadMap";
import Regions from "../../Pages/Regions/Regions";
import NewsAndAnnouncement from "../../Pages/newsAndAnnouncement/NewsAndAnnouncement";
import Contact from "../../Pages/Contact/Contact";
import Instructions from "../../Pages/Instructions/Instructions";
import Faq from "../../Pages/Faq/Faq";
import VideoApply from "../../Pages/VideoApply/VideoApply";
import ApplyForm from "../../Pages/ApplyForm/ApplyForm";
import MenuList from "../../Elements/MenuList/MenuList";
import TimeLine from "../../Elements/TimeLine";
import DocsUrl from "../../Pages/DocsUrl/DocsUrl";
import Posts from "../../Pages/Posts/Posts";
import {connect} from "react-redux";
import Legislation from "../../Pages/CreditContent/Legislation/Legislation";
import Image from "../../../assets/img/Image";

const Routes = (props) => {
    const [transitionProps , setTransitionProps] = useState(
        {
            // soldan saga
            from: { opacity: 0, transform: "translate(100%, 0)" },
            enter: { opacity: 1, transform: "translate(0%, 0)" },
            leave: { opacity: 0, transform: "translate(-50%, 0)" }
            // sagdan sola
        }
    )
    const {location} = useContext(__RouterContext);
    const transitions = useTransition(location , location => location.pathname,transitionProps);
    return (
        <div>
            {!props.toggleReducer &&
                <TimeLine
                    setTransitionProps={setTransitionProps}
                />
            }
            <MenuList />
            {transitions.map(({item , props , key}) =>{
                return (
                    <animated.div key={key} style={props}>
                        <Switch location={item}>
                            <Route path="/" exact component={Home} />
                            <Route path="/credit" exact component={Credit} />
                            <Route path="/about/:id?" exact component={About} />
                            <Route path="/products" exact component={Products} />
                            <Route path="/client-history" exact component={ClientHistory} />
                            <Route path="/credit-organization" exact >
                                <CreditOrganization type={'banks'} />
                            </Route>
                            <Route path="/customers" exact >
                                <CreditOrganization type={'customers'} />
                            </Route>
                            <Route path="/road-map" exact >
                                    <RoadMap/>
                            </Route>
                            <Route path="/regions/:type?/:id?" component={Regions} exact />
                            <Route path="/posts" exact component={Posts} />
                            <Route path="/search" exact component={NewsAndAnnouncement} />
                            <Route path="/posts/full/:id?" exact component={FullPost} />
                            <Route path="/docs/:id?" exact component={DocsUrl} />
                            <Route path="/owner-map" exact >
                                <div className="p-md-5 p-3 mt-4">
                                    <Legislation id={5}/>
                                    <div>
                                        <img src={Image.Map} alt="" className="bg-image"/>
                                    </div>
                                </div>
                            </Route>
                            <Route path="/contact" exact component={Contact} />
                            <Route path="/credit-content/:id?" exact component={CreditContent} />
                            <Route path="/credit-calculator/:id?" exact component={CreditCalculator} />
                            <Route path="/about-content" exact component={AboutContent} />
                            <Route path="/gallery" exact component={Gallery} />
                            <Route path="/gallery/full/:id?" exact component={Full} />
                            <Route path="/instructions" exact component={Instructions} />
                            <Route path="/faq" exact component={Faq} />
                            <Route path="/video-apply" exact component={VideoApply} />
                            <Route path="/apply-form" exact component={ApplyForm} />
                        </Switch>
                    </animated.div>
                )
            })}

        </div>
    );


}

const mapStateToProps = ({toggleReducer}) => {
    return {toggleReducer}
}
export default connect(mapStateToProps , null)(Routes);

