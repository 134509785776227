import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/scss/modal-video.scss"
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './assets/css/style.css'
import App from './components/App';
import store from "./redux/store";
import { Provider } from 'react-redux';
import history from './const/history';
import { Router } from 'react-router-dom';

ReactDOM.render(
    <Provider store={store}>
         <Router history={history}>
             <App />
         </Router>
    </Provider>,
  document.getElementById('root')
);

