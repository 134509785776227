import React, {useEffect, useState, useRef} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import InputGroup from 'react-bootstrap/InputGroup'
import { Button, FormControl } from 'react-bootstrap';
import {connect} from "react-redux";
import {getFaqs} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function Faq(props) {
    const {t , i18n} = useTranslation()
    const {getFaqs, faqs , faqsData} = props
    const [value, setValue] = useState('')
    const [text, setText] = useState('')

    const trigger = 1
    useEffect(() => {
        let mounted = true
        if (mounted){
            if (!faqs.length ||  i18n.language !== props.lang){
                getFaqs()
            }
        }
        return function cleanup(){
            mounted = false
        }
    }, [trigger , t])

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')} </title>
            </Helmet>
            <div className="position-relative container-fluid">
                <div className="row pt-5 mt-5">
                    <div className="col-12">
                        <div className="page-head d-md-flex d-block justify-content-between p-0">
                            <div className={'d-flex align-items-center'}>
                                <Link to="/" className=" mb-2 mt-lg-2 mt-md-2 mt-sm-2 mt-5 pt-lg-0 pt-md-0 pt-sm-2 pt-3">
                                    <img src={Image.Back} alt=""/>
                                </Link>
                                <p>{faqsData.title}</p>
                            </div>
                            <div className="faq-search">
                                <InputGroup className="mb-3">
                                    <FormControl
                                        value={value}
                                        onChange={e => setValue(e.target.value)}
                                        placeholder={t('contact.search')}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={()=>{setText(value)}} variant="outline-secondary">
                                            <img src={Image.Search} alt=""/>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="faq-text">
                            <p>
                                {faqsData.description}
                            </p>
                        </div>
                        <div className="faqs-slider">
                            <div className="row  video-apply-text px-lg-5 mx-md-5 mx-1 justify-content-center">
                                {faqs?.length > 0  ?
                                    faqs?.filter(item => {
                                            if (!value) return true
                                            if (item.locale.question.includes(text) || item.locale.answer.includes(text)) {
                                                return true
                                            }
                                        })
                                        .map((faqs , index) =>(
                                            <div className="col-md-6 mb-4">
                                                <div className="faq-card w-100">
                                                    <div className="d-flex justify-content-start">
                                                        <p className={'numb'}>{pad(index+1)}</p>
                                                    </div>
                                                    <strong>{faqs.locale.question}</strong>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: faqs.locale.answer
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    :  ' '
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ faqs, faqsData , lang }) => {
    return { faqs , faqsData , lang };
}


export default connect(mapStateToProps, {getFaqs})(Faq);
