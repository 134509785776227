import React , {useState , useEffect} from 'react';
import sif from "../../../const/api";
import { Redirect } from "react-router-dom";
import history from "../../../const/history";
function Search(props) {
    const [keyword , setKeyword] = useState('');
    const [data , setData] = useState(null);

    const  onsubmit =  (e) =>{
        e.preventDefault()
        sif
            .get("search" , {params:{keyword}})
            .then((res) => {
                setData(res.data.content)
                document.getElementById('search').blur();
                history.push({
                    pathname: '/',
                    state: {results: res.data.content}
                })
                history.push({
                    pathname: '/search',
                    state: {results: res.data.content}
                });
                setKeyword('');
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <>
            <form onSubmit={onsubmit} className="d-md-flex d-none search-bar">
                <input onChange={(e)=>{setKeyword(e.target.value)}} id={'search'} type="search" name="search" pattern=".*\S.*" value={keyword} required />
                <button className="search-btn" type="submit">
                    <span>Search</span>
                </button>
            </form>
        </>
    );
}


export default Search;
