
import React, {useEffect, useState} from 'react';
import Image from "../../../assets/img/Image";
import {Link} from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'
import Legislation from "../CreditContent/Legislation/Legislation";
import Tab from 'react-bootstrap/Tab'
import { connect } from "react-redux";
import { getLocation} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
function DocsUrl(props) {
    const {id} = props.match.params;
    const trigger = 1;
    useEffect(()=>{
        id && setKey(id)
    },[trigger,id]);
    const {t} = useTranslation();
    const [key, setKey] = useState('0');
    let  legistationLinks = [
        {name:t('legistation.constitution')},
        {name:t('legistation.laws')},
        {name:t('legistation.orders')},
        {name:t('legistation.decisions')},
        {name:t('legistation.internalRules')},
    ]

    return (
        <div className="main">
            <div className="position-relative">
                <div className="animated fadeIn container-fluid">
                    <div className="pt-5 mt-lg-0 mt-5">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            {legistationLinks.map((l,i)=> (
                                <Tab key={i} eventKey={i} title={l.name.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())} tabClassName="line-bottom">
                                    <Legislation name={l.name.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())} id={i}  />
                                </Tab>
                            ) )}
                        </Tabs>
                    </div>

                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ product , location }) => {
    return { product , location };
}

export default connect(mapStateToProps, {  getLocation })(DocsUrl);
