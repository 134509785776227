import React from 'react';
import Image from "../../../assets/img/Image";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function CompanyBox(props) {
    const {t} = useTranslation()
    const {name  , image  , phone , address} = props
    return (
        <div className="box w-100 animated zoomIn position-relative">
            <img src={image} alt="" className="company"/>
            <div className="companies-text">
                <div className="d-flex justify-content-between ">
                    <div>
                        <p>{name}</p>
                    </div>
                    <Link>
                        <img src={Image.Navigation} alt="" className="navigation"/>
                    </Link>
                </div>
                <div className="d-flex align-items-center ">
                    <div>
                        <img src={Image.Location} alt="" className="location"/>
                    </div>
                    <div>
                        <span>{address}</span>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div>
                        <img src={Image.Phone} alt="" className="phone"/>
                    </div>
                    <div>
                        <span>{t('contact.tel')}: {phone}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CompanyBox;
