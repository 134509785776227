import React, {useEffect , useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import api from "../../../const/api";
import ContactForm from "../../Elements/ContactInfo/ContactForm";
import GoogleM from "../../Elements/GoogleMap/GoogleM";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {getContact} from "../../../redux/actions";
import Social from "../../Elements/Social/Social";
import {Helmet} from "react-helmet";

const Contact = (props) => {

    const {getContact ,contactInfo } = props
    const [spin , setSpin] = useState(false)
    const [showmap , setShowMap] = useState(false)
    const [phones , setPhones] = useState([])
    const [fax , setFax] = useState([])
    const [zoom , setZoom] = useState([])
    const [mail , setMail] = useState([])
    const [infos , setInfos] = useState({})
    const { t , i18n } = useTranslation();

    const [map , setMap] = useState({
        lat: undefined,
        lng: undefined
    })

    useEffect(()=>{
        if(!contactInfo.length){
            getContact()
        }
        else{
            setInfos(contactInfo[0])
            setPhones(contactInfo[0]?.contacts?.filter((e)=> {
                return e.type === 1
            }))
            setFax(contactInfo[0]?.contacts?.filter((e)=> {
                return e.type === 2
            }))
            setMail(contactInfo[0]?.contacts?.filter((e)=> {
                return e.type === 3
            }))
            setZoom(contactInfo[0]?.contacts?.find((e)=> {
                return e.type === 4
            }))
            let obj = {
                lat: eval(contactInfo[0]?.latitude),
                lng: eval(contactInfo[0]?.longitude),
            }
            setMap(obj)
            setShowMap(true)
        }
    }, [t])

    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')}  - {t('menu.contact')} </title>
            </Helmet>
            <div className="animated fadeIn position-relative">
                <div className="container-fluid">
                    <div className="col-11 mt-5 pt-5 margin">
                        <div className="row">
                            <div className=" col-lg-6 col-md-12 col-sm-12 col-12 p-0">
                                <>
                                            <div className="d-flex page-head">
                                                <p>{t('contact.title')}</p>
                                            </div>
                                            {/*<div>*/}
                                            {/*    {mail.map((p , i ) => (*/}
                                            {/*        <a key={i} href={`mailto:${p.value}`} target={'_blank'} className="header-email">{p.value}</a>*/}
                                            {/*    ))}*/}

                                            {/*    <p className="header-text">QAYNAR XƏTT :*/}
                                            {/*        {phones[0]?.value}*/}
                                            {/*    </p>*/}
                                            {/*</div>*/}

                                            <div className="contact">
                                                <p>{t('contact.address')}:</p>
                                                <p>  {infos?.locale?.address}}</p>
                                            </div>
                                            <div className="contact">
                                                <p>{t('contact.tel')}:</p>
                                                {phones?.map((phone , i) => (
                                                    <p key={i}>{phone.value}</p>
                                                ))}
                                            </div>
                                            <div className="contact">
                                                <p>{t('contact.fax')}:</p>
                                                {fax?.map((phone , i) => (
                                                    <p key={i} >{phone.value}</p>
                                                ))}
                                            </div>
                                            <div className="contact">
                                                <p>{t('contact.mail')}:</p>
                                                {mail?.map((p , i ) => (
                                                    <p key={i}>
                                                        <a href={`mailto:${p.value}`} className={'ml-0'} target={'_blank'}>
                                                            {p.value}
                                                        </a>
                                                    </p>
                                                ))}
                                            </div>
                                            <div className="contact-number d-flex align-items-center">
                                                <div>
                                                    <img src={Image.Microphone} alt=""/>
                                                </div>
                                                <div>
                                                    <p>{t('contact.service')}</p>
                                                    <span>{t('contact.serviceText')}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                {/*<a href={zoom.value} target="_blank" className="onlinechat text-decoration-none d-flex align-items-center">*/}
                                                {/*    <div>*/}
                                                {/*        <img src={Image.OnlineChat} alt=""/>*/}
                                                {/*    </div>*/}
                                                {/*    <div>*/}
                                                {/*        <p>Onlayn görüş</p>*/}
                                                {/*    </div>*/}
                                                {/*</a>*/}

                                                <Link to={'/video-apply'} className="onlinechat text-decoration-none d-flex align-items-center">
                                                    <div>
                                                        <p>{t('contact.videoApply')}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                </>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 form p-0">
                                <ContactForm/>
                                {(map.lat && showmap) &&
                                    <div className="map">
                                        <GoogleM obj={map}/>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <Social/>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>

            </div>
        </div>
    );
}


const mapStateToProps = ({ contactInfo ,lang }) => {
    return {
        contactInfo ,lang
    };
};

export default connect(mapStateToProps, { getContact })(Contact);

