import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import FormApply from "../../Elements/FormApply/FormApply";
import {connect} from "react-redux";
import {setAccept} from "../../../redux/actions";
import history from "../../../const/history";
import {useTranslation} from "react-i18next";

function ApplyForm(props) {
    const {t} = useTranslation();
    useEffect(()=>{
        if (!props.accepted) {
            history.push('/video-apply')
        }
    } , [props.accepted])
    return (
        <div className="main">
            <div className="position-relative container-fluid">
                <div className="mt-md-4 mt-5 pt-lg-5 pt-md-0 pt-5">
                    <div className="d-flex align-items-center">
                        <Link onClick={() => {props.setAccept(false)}} to="/video-apply" className=" mt-lg-4 mt-md-4 mt-sm-5  pt-lg-0 pt-md-0 pt-sm-2 pt-3">
                            <img src={Image.Back} alt=""/>
                        </Link>
                        <div className="page-head  p-0">
                            <p>{t('videoApply.title')}</p>
                        </div>
                    </div>
                    <FormApply/>
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ accepted }) => {
    return { accepted }
};


export default connect(mapStateToProps, {setAccept })(ApplyForm);
