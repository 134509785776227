import React, {useRef , useState , useEffect} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import Slider from "react-slick";
import moment from "moment";
import history from "../../../const/history";
import api from "../../../const/api";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getLocation, getPostList} from "../../../redux/actions";

function PostPage(props) {
    const { t } = useTranslation();
    let [trigger, setTrigger] = useState(0);
    const [warn , showWarn] = useState(false)
    const [postlist , setPostList] = useState();
    const [spin , setSpin] = useState(false);
    const {getLocation  , locations} = props
    const getPostList = () => {
        setSpin(true)
        api.get(`posts` ,{ params: { category_id: props.id } }).then((res) => {
            res.data.content && setSpin(false)
            setPostList(res.data.content);
        });
    };


    useEffect(() => {
        if(props.name){
            getLocation(props.name)
        }
        if ( props.id ){
            getPostList();
        }
        if(props.location?.state?.results){
            setPostList(props.location.state?.results);
            // eslint-disable-next-line no-unused-expressions
            !props.location.state?.results.length ?  showWarn(true) : showWarn(false)
        }
        if( !props.id && !props.location.state?.results){
            history.push('/')
        }

    }, [trigger]);




    const news = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed: 1000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed:2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };
    const next = () => {
        news.current.slickNext();
    }
    const previous = () => {
        news.current.slickPrev();
    }
    return (
        <div className="main">
            <div className="position-relative">
                {!spin &&
                <div className="container-fluid animated fadeIn">
                    <div>
                        <div className="row align-items-center ">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="d-flex align-items-center">
                                    {/*<Link to={'/'} className="about-back">*/}
                                    {/*    <img src={Image.Back} alt=""/>*/}
                                    {/*</Link>*/}
                                    <div className="page-head mt-0 ml-3 p-0">
                                        <p> {props.location?.state?.results ? t('posts.searchResults') : props.name}</p>
                                    </div>
                                </div>


                            </div>

                            {
                                postlist?.length > 4 &&
                                <div className="arrows mt-5 ml-lg-6 ml-md-0 ml-sm-0 ml-0 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start p-0">
                                    <Link onClick={() => {previous()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                    </Link>
                                    <Link onClick={() => {next()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                    </Link>
                                </div>
                            }
                        </div>
                        {!spin &&
                        <div className="position-relative client-img p-0">
                            <div className="client-slider">
                                <div className="client-photo">
                                    {postlist?.length < 5 ?
                                        <div className="row">
                                            {postlist?.reverse().map((l , i)=>(
                                                <div key={i} className="col-md-6 mb-3 px-2  col-12">
                                                    <Link className={'d-block mx-0'} to={ `/posts/full/${l.id}`} >
                                                        <div className="w-100 position-relative img">
                                                            <img className={'w-100'} src={l.photo.path} alt=""/>
                                                            <div className="lay"></div>
                                                            <div className="client-photo-text col-lg-10 col-md-10 col-sm-10 col-10">
                                                                <p>{l.locale.title}</p>
                                                                <div className="mt-2">
                                                                    <span>{t('posts.dateOf')}: {moment(l.date).format("DD-MM-YYYY hh:mm")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>:
                                        <Slider
                                            className='mainslider'
                                            ref={slider => (news.current = slider)}
                                            {...settings}
                                        >
                                            {postlist?.reverse().map((l , i)=>(
                                                <Link to={ `/posts/full/${l.id}`} key={i} className="mb-3 pr-3">
                                                    <div className="position-relative img">
                                                        <img src={l.photo.path} alt=""/>
                                                        <div className="lay"></div>
                                                        <div className="client-photo-text col-lg-10 col-md-10 col-sm-10 col-10">
                                                            <p>{l.locale?.title}</p>
                                                            <div className="mt-2">
                                                                <span>{t('posts.dateOf')} : {moment(l.date).format("DD-MM-YYYY hh:mm")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </Slider>
                                    }

                                </div>
                            </div>
                            {warn && <h5>{t('posts.searchError')}</h5>}
                        </div>
                        }
                    </div>
                </div>
                }
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ locations }) => {
    return {  locations };
}

export default connect(mapStateToProps, {  getLocation })(PostPage);
