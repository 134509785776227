import React, {useEffect, Fragment, useState} from 'react';
import {Link, NavLink , useLocation} from "react-router-dom";
import history from "../../const/history";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getCategories} from "../../redux/actions";
function TimeLine(props) {
    const {t , i18n} = useTranslation()
    const [number,setNumber] = useState(0);
    const [links , setLinks] = useState([]);
    const [contentlinks , setContentLinks] = useState([])
    const location = useLocation()



    const getCategories = async  ()=>{
        let defLinks = [ '','about', 'products', 'credit-organization','road-map', 'docs/0','posts' , 'gallery' , 'contact'];
        let  defCon = [
            {name: t('menu.homePage'), number: '1', to: '/'},
            {name: t('menu.about'), number: '2', to: '/about'},
            {name: t('menu.products'), number: '3', to: '/products'},
            {name: t('menu.creditOrganization'), number: '4', to: '/credit-organization'},
            {name: t('menu.roadMap'), number: '5', to: '/road-map'},
            {name: t('menu.legistation'), number: '6', to: '/docs/0'},
            {name: t('menu.news'), number: '7', to: '/posts'},
            {name: t('menu.gallery'), number: '8', to: '/gallery'},
            {name: t('menu.contact'), number: '9', to: '/contact'},
        ]
            // props.categories.filter((d)=>{return d.status === 1 }).reverse().map((p, index) => {
            //     defLinks.splice(6, 0,   `posts/${p.id}`);
            //     defCon.splice(6, 0,   {
            //         name:p.locale.name.toUpperCase(),
            //         number: (index+6).toString(),
            //         to:`/posts/${p.id}`
            //     })
            // })
            setLinks(defLinks);
            setContentLinks(defCon.map((d, index) => {
                d['active'] = index < 10;
                d['id'] = index +1;
                return d;
            }))
    }

    useEffect(() => {
        getCategories()
    }, [location , props.lang])

    function debounce(func, timeout = 200){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }



    // document.onmousewheel = debounce( (e) =>
    //     {
    //         if(!props.hover){
    //             if (links.indexOf(history.location.pathname.substring(1)) !== -1){
    //
    //                 if (e.wheelDeltaY  < 0){
    //                     if(number === links.length){
    //                         pageChanged(0)
    //                         setNumber(0)
    //                     }
    //                     else {
    //                         pageChanged(number+1)
    //                         setNumber( number+1)
    //                     }
    //                     props.setTransitionProps({
    //                         // sagdan sola
    //                         from: { opacity: 0, transform: "translate(100%, 0)" },
    //                         enter: { opacity: 1, transform: "translate(0%, 0)" },
    //                         leave: { opacity: 0, transform: "translate(-50%, 0)" }
    //                     })
    //                 }
    //                 if (e.wheelDeltaY  > 0){
    //                     if(number === 0){
    //                         pageChanged(links.length-1)
    //                         setNumber( links.length-1)
    //                     }
    //                     else {
    //                         pageChanged(number-1)
    //                         setNumber( number-1)
    //                     }
    //                     props.setTransitionProps({
    //                         // sagdan sola
    //                         from: { opacity: 0, transform: "translate(-50%, 0)" },
    //                         enter: { opacity: 1, transform: "translate(0%, 0)" },
    //                         leave: { opacity: 0, transform: "translate(100%, 0)" }
    //                     })
    //                 }
    //                 if (number === links.length){
    //                     history.push({
    //                         pathname: `/`
    //                     })
    //                 }
    //                 else{
    //                     history.push({
    //                         pathname: `/${links[number]}`,
    //                         state: {name: contentlinks[number]?.name}
    //                     })
    //                 }
    //
    //             }
    //         }
    //     }
    // )

    const pageChanged = (l) => {
        console.log(l);
        let min = -1;
        let max = 10;
        if(l > 5 && l <= contentlinks.length - 5){
            min += l - 5;
            max += l - 5;
        }
        else if(l > contentlinks.length - 5){
            min = contentlinks.length - 11;
            max = contentlinks.length;
        }
        let defs = [...contentlinks];
        setContentLinks(defs.map((d,index)=>{
            d['active'] = index > min && index < max;
            return d;
        }))
        setTimeout(() => {
            console.log(contentlinks);
        }, 1000)
    }


    return (
        <>
            {
                links.indexOf(history.location.pathname.substring(1)) !== -1 &&
                <div id="content" className='d-none  d-md-block'>
                    <ul  className="timeline animated fadeInRightBig">
                        {contentlinks.filter(c=>c.active).map((links, index) => (
                            <Fragment key={index}>
                                <li className="event text-right text-uppercase">
                                    <NavLink as={Link} activeClassName='text-white' to="/"></NavLink>
                                </li>
                                <li
                                    onClick={ () =>{setNumber(index) }  }
                                    className={`${window.location.pathname === links.to ? 'activeLi' : ''} event text-right text-uppercase`}
                                >
                                    <NavLink as={Link}
                                             exact
                                             activeClassName='font-weight-bold active'
                                             to={{
                                                 pathname: `${links.to}`,
                                                 state: { name: links.name }
                                             }}>
                                        <>
                                            {/*{*/}
                                            {/*    window.location.pathname === links.to ?*/}
                                            {/*        <span className="name">{links.name}</span> :  <span className="number">{links.id}</span>*/}
                                            {/*}*/}
                                            <span className={`name`}>{links.name}</span>
                                        </>
                                    </NavLink>
                                </li>
                            </Fragment>
                        ))}
                    </ul>
                </div>

            }
        </>

    );
}

const mapStateToProps = ({ hover ,categories  , lang}) => {
    return {hover ,categories , lang};
};

export default connect(mapStateToProps, {getCategories})(TimeLine);

