import React , {useState , useEffect} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import api from "../../../const/api";
import moment from "moment";
import Lightbox from 'react-image-lightbox';
import {useTranslation} from "react-i18next";

function FullPost(props) {
    const [spin , setSpin] = useState(false);
    const [postData , setPostData] = useState({});
    const [current , setCurrent] = useState('')
    const {t} = useTranslation();

    const  handleCloseModal = (e) =>{
        e && e.preventDefault();
        setCurrent('')
    }
    const handleClickImage = (e , image) =>{
        e && e.preventDefault();
        setCurrent(image)
    }



    const getPost = async () => {
        setSpin(true);
        await api.get(`posts/${props.match.params.id}`)
            .then((res) => {
                setSpin(false);
                setPostData(res.data.content);
            });
    };

    useEffect(()=>{
        getPost()
    },[props.match.params.id , t]);

    return (
        <div className="main">
            <div className="position-relative">
                <div className="container-fluid">
                    {!spin &&
                    <div className="mt-5 pt-5 animated fadeIn about-content">
                        <div className="d-flex justify-content-between align-items-center">
                            <Link onClick={()=>{window.history.back();}} className="about-back">
                                <img src={Image.Back} alt=""/>
                            </Link>
                            <div className="d-flex page-head p-0">
                                <p>{postData.locale?.title}</p>
                            </div>
                        </div>
                        <div className="date d-flex w-25">
                            <p> {moment(postData.date).format("DD-MM-YYYY hh:mm")}</p>
                        </div>
                        <div className="row">
                            <div className="col-md-5 col-12">
                                <div className="img">
                                    <img src={postData?.photo?.path} onClick={ e => handleClickImage(e, postData?.photo?.path)} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="mt-0 transparent aboutcontent-text">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html:postData.locale?.body
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                    {current &&
                    <Lightbox
                        mainSrc={current}
                        onCloseRequest={handleCloseModal}
                    />
                    }
                </div>

            </div>
        </div>
    );
}

export default FullPost;


