import React, {useEffect , useState} from 'react';
import Image from "../../../assets/img/Image";
import {getContact} from "../../../redux/actions";
import {connect} from "react-redux";
import SocialElement from "./SocialElement";
function Social(props) {
    const {getContact ,contactInfo } = props
    const [facebook, setFacebook] = useState([]);
    const [instagram, setInstagram] = useState([]);
    const [linkedin, setLinkedin] = useState([]);
    const [youtube, setYoutube] = useState([]);
    const [twitter, setTwitter] = useState([]);
    useEffect(() =>{
        let mounted = true
        if (mounted){
            if(!contactInfo.length){
                getContact()
            }
            else {
                setFacebook(
                    contactInfo[0].contacts?.filter((e) => {
                        return e.type === 5;
                    })
                );
                setInstagram(
                    contactInfo[0].contacts?.filter((e) => {
                        return e.type === 9;
                    })
                );
                setYoutube(
                    contactInfo[0].contacts?.filter((e) => {
                        return e.type === 6;
                    })
                );
                setTwitter(
                    contactInfo[0].contacts?.filter((e) => {
                        return e.type === 7;
                    })
                );
                setLinkedin(
                    contactInfo[0].contacts?.filter((e) => {
                        return e.type === 8;
                    })
                );
            }
        }
        return function cleanup() {
            mounted = false
        }
    } ,[])

    const Social = [
        {
            data : facebook,
            src:Image.Facebook
        },
        {
            data : instagram,
            src:Image.Instagram
        },
        {
            data : twitter,
            src:Image.Twitter
        },
        {
            data : youtube,
            src:Image.Youtube
        },
        {
            data : linkedin,
            src:Image.Linkedin
        },
    ]

    return (
        <div className="social-icon d-flex">
            {
                Social?.map((main , index) => (
                    <React.Fragment key={index}>
                        {main.data?.map((social, i) => (
                            <SocialElement key={i} src={main.src} href={social.value} />
                        ))}
                    </React.Fragment>
                ) )
            }
        </div>
    );
}

const mapStateToProps = ({ contactInfo }) => {
    return {
        contactInfo
    };
};

export default connect(mapStateToProps, { getContact })(Social);

