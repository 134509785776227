import React, {useState, useEffect, useRef} from 'react';
import Image from "../../../assets/img/Image";
import Slider from "react-slick";
import {Link } from "react-router-dom";
import Faq from "../../../assets/icons/Faq";
import Loan from "../../../assets/icons/Loan";
import Clients from "../../../assets/icons/Clients";
import Video from "../../../assets/icons/Video";
import Platform from "../../../assets/icons/Platform";
import {useTranslation} from "react-i18next";
import Social from "../../Elements/Social/Social";
import {connect} from "react-redux";
import {getAbout , getCategories, getPosts , getContact} from "../../../redux/actions";
import {Helmet} from "react-helmet";
import moment from "moment";
function Home(props) {
    const [show , setSHow] = useState(true)
    const [platform , setPlatform] = useState([])
    const {about , categories , getAbout , getCategories, getPosts  , posts ,getContact,contactInfo, } = props
    const {t , i18n} = useTranslation();
    useEffect(()=>{
        let mounted =  true;
        if (mounted){
            if(posts.length  === 0){
                getPosts()
            }
            getContact()
            if (!about?.id || i18n.language !== props.lang) {
                setSHow(false)
                getAbout()
            }
            if (categories.length === 0 || i18n.language !== props.lang) {
                getCategories()
                setSHow(false)
            }
            setSHow(true)
            setSHow(true)
        }
        return function cleanup() {
            mounted = false
        }
    },[t , 1])

    const homeVideos = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows : false,
        rows: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed:500,
                    autoplaySpeed: 2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed:500,
                    autoplaySpeed: 2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };
    const next = () => {
        homeVideos.current.slickNext();
    }
    const previous = () => {
        homeVideos.current.slickPrev();
    }
    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')}  - {t('menu.homePage')}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7 col-12 section">
                            <div className="home-text animated zoomIn">
                                <p>
                                    {about?.locale?.slogan}
                                </p>
                            </div>
                            <div className="icons">
                                <div className={'row'}>
                                    <Link to={'/products'} className="nav-link text-dark m-md-0 my-4 col-lg-2 col-md-6 col-sm-6 col-12">
                                        <Loan/>
                                        <p>{t('home.credit')}</p>
                                    </Link>
                                    <a href={
                                        contactInfo.length && contactInfo[0]?.contacts?.find((e) => {
                                            return e.type === 10
                                        }).value
                                    } className="nav-link text-dark m-md-"target={'_blank'} className="nav-link text-dark m-md-0 my-4 col-lg-2 col-md-6 col-sm-6 col-12">
                                        <Platform/>
                                        <p>{t('home.electronCreditPlatform')}</p>
                                    </a>
                                    <Link to={'/instructions'} className="nav-link text-dark m-md-0 my-4 col-lg-2 col-md-6 col-sm-6 col-12">
                                        <Video/>
                                        <p>{t('home.videoInstructions')}</p>
                                    </Link>
                                    <Link to={'/customers'} className="nav-link text-dark m-md-0 my-4 col-lg-2 col-md-6 col-sm-6 col-12">
                                        <Clients/>
                                        <p>{t('home.customers')}</p>
                                    </Link>
                                    <Link to={'/faq'} className="nav-link text-dark m-md-0 my-4 col-lg-2 col-md-6 col-sm-6 col-12">
                                        <Faq/>
                                        <p>{t('home.questions')}</p>
                                    </Link>
                                </div>
                            </div>

                            {posts.length > 0 &&
                              <div className="home-videos-slider mt-5 pt-2">
                                {
                                    posts.length <= 2 ?
                                        <div className="row">
                                            {
                                                posts.length ? posts.map((l, i) => (
                                                    <Link className={'col-md-6 text-decoration-none mx-0 col-sm-12'} to={ `/posts/full/${l.id}`} key={i}>
                                                        <div className="home-videos-box  position-relative">
                                                            <div className="home-videos">
                                                                <div className="photo">
                                                                    <img src={l.photo.path} alt=""/>
                                                                    <div className="lay"></div>
                                                                </div>
                                                                <img src={Image.Play} alt="" className="play-icon"/>
                                                            </div>
                                                            <div className="home-videos-text">
                                                                <p className={'line-clamp line-4'}>{l.locale?.title}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )) : ' '
                                            }
                                        </div> :
                                        <Slider
                                            className='home-slider'
                                            ref={slider => (homeVideos.current = slider)}
                                            {...settings}
                                        >
                                            {posts.length ? posts?.map((l, i) => (
                                                <Link className={'text-decoration-none'} to={ `/posts/full/${l.id}`} key={i}>
                                                    <div className="home-videos-box mr-3  position-relative">
                                                        <div className="home-videos">
                                                            <div className="photo">
                                                                <img src={l.photo.path} alt=""/>
                                                                <div className="lay"></div>
                                                            </div>
                                                            <img src={Image.Play} alt="" className="play-icon"/>
                                                        </div>
                                                        <div className="home-videos-text">
                                                            <p className={'line-clamp line-4'}>{l.locale?.title}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )) : ' '}
                                        </Slider>
                                }
                                {posts.length > 2 &&
                                <div className="d-flex align-items-center home-videos-arrow">
                                    <Link onClick={()=>{previous()}} className="prev">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.203" height="30.394" viewBox="0 0 9.203 30.394"><path d="M0,26.1l1.249-1.215,2.537,2.465V0h1.9V27.309l2.6-2.434L9.2,25.8,5.4,30.133l-.358-.347-.625.608Z" fill="#A6926C" opacity="0.8"/></svg>
                                    </Link>
                                    <Link  onClick={()=>{next()}} className="next">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.203" height="30.394" viewBox="0 0 9.203 30.394"><path d="M0,26.1l1.249-1.215,2.537,2.465V0h1.9V27.309l2.6-2.434L9.2,25.8,5.4,30.133l-.358-.347-.625.608Z" fill="#A6926C" opacity="0.8"/></svg>
                                    </Link>
                                </div>}
                            </div>
                            }

                        </div>
                        <div className="main-logo " onClick={()=>{
                            console.log(contactInfo[0])
                        }}>
                            <img className={'animated zoomIn'} src={Image.MainLogo} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <Social/>
                </div>
                <div>
                    <img src={Image.Map} alt=""className="bg-image"/>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = ({ about , categories, posts  , contactInfo}) => {
    return {about, categories, posts , contactInfo};
};
export default connect(mapStateToProps, { getAbout , getCategories, getPosts,getContact  })(Home);
