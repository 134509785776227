import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import sif from "../../../../const/api";
import './index.css';
import OrgChart from "react-orgchart";

function Mechanism(props) {
    const {t} = useTranslation();
    const [structures, setStructures] = useState([]);
    const [data, setD] = useState([]);
    const {id} = props;
    useEffect(()=>{
        sif.get(`products/charts/options/${id}`).then((res)=>{
            res.data.content.length  && setStructures(res.data.content[0])
            createData(res.data.content[0])
        }).catch((err)=>{
            console.log(err)
        })
    } ,[t])


    const createData = (data)  =>{
        console.log(data)
        let obj = {
            ...data,
            id:data?.id,
            value:data?.id,
            locales: data?.locales,
            parent_id:data?.parent_id,
            yes: data?.yes,
            title:data?.locale.name,
            description:data?.locale.description,
            children:data?.children,
            locale:data?.locale
        }
        data?.children && data.children.forEach((s, i)=>{
            obj.children[i] = {
                id:s?.id,
                value:s?.id,
                locales: s?.locales,
                parent_id:s?.parent_id,
                yes: s?.yes,
                title:s?.locale.name,
                description:s?.locale.description,
                children:s?.children,
                locale:s.locale
            }
            createData(s)
        })

        setD([obj])
    }

    const MyNodeComponent = ({node}) => {
        return (
           <>
               <div className={`initechNode animated zoomIn ${node.yes === 1 ? 'green' : 'red'}`} >
                   <p className={'text'}>{node.title ? node?.title : node?.locale?.name}</p>
                   <div className="divider">
                       <div className="dot"></div>
                   </div>
                   <div className={'w-100 h-100 d-flex jus  tify-content-center align-items-center'}>
                       <div className="number">
                           {node?.locale?.description ? node?.locale?.description : node.description}
                       </div>
                   </div>
               </div>
           </>
        );
    };


    return (
        <div className="row animated mt-5 fadeIn">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div>
                    <h1 className={'font-weight-bold'}>{props.locations}</h1>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="credit-about">
                    <p className={'font-weight-bold'}>{t('mechanism.mechanismTitle')}</p>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div className="mechanism container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {structures?.id &&
                                <div className="mainn">
                                    <div className="rotate">
                                        <OrgChart  className={'animated mt-2 fadeInUp w-100'}  tree={structures} NodeComponent={MyNodeComponent} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


const mapStateToProps = ({ locations }) => {
    return { locations };
}

export default connect(mapStateToProps)(Mechanism);
