import types from "./../types";
import sif from "../../const/api";
import {az} from '../../lang/az'
export const toggleMenu = () => {
    return {
        type: types.TOGGLE
    }
}


export const getLocation = (payload) => {
    return {
        type: types.LOCATION_SUCCESS,
        payload,
    };
};

export const setHover = (hover) => {
    return {
        type: hover ? types.HOVER : types.OUT,
    };
};

export const setAccept = (accept) => {
    return {
        type: accept ? types.ACCEPT : types.NOTACCEPT,
    };
};


export const getProductList = () => async (dispatch) => {
    sif
        .get("products")
        .then((res) => {
            dispatch({
                type: types.GET_PRODUCT,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_PRODUCT_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getLegistation = (id) => async (dispatch) => {
    sif
        .get("legislation/links")
        .then((res) => {
            dispatch({
                type: types.GET_LEGISTATION,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_LEGISTATION_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getRoadMap = (id) => async (dispatch) => {
    sif
        .get("investor/roadmap")
        .then((res) => {
            dispatch({
                type: types.GET_ROAD_MAP,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_ROAD_MAP_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getInstructions = (id) => async (dispatch) => {
    sif
        .get("instructions")
        .then((res) => {
            dispatch({
                type: types.GET_INSTRUCTIONS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_INSTRUCTIONS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getFaqs = () => async (dispatch) => {
    sif
        .get("faqs")
        .then((res) => {
            dispatch({
                type: types.GET_FAQS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_FAQS_ERROR,
                payload: [],
            });
        });
};

export const getPartners = () => async (dispatch) => {
    sif
        .get("international-partners")
        .then((res) => {
            dispatch({
                type: types.GET_PARTNERS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_PARTNERS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};
export const getPartnerPage = () => async (dispatch) => {
    sif
        .get("international-partners/page")
        .then((res) => {
            dispatch({
                type: types.GET_PARTNERPAGE,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_PARTNERPAGE_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};



export const getAbout = () => async (dispatch) => {
    sif
        .get("company/about")
        .then((res) => {
            dispatch({
                type: types.GET_ABOUT,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_ABOUT_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getReportItems = (id) => async (dispatch) => {
    sif
        .get(  `reports/${id}`)
        .then((res) => {
            dispatch({
                type: types.GET_REPORT_ITEMS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_REPORT_ITEMS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};




export const getBanks = () => async (dispatch) => {
    sif
        .get("banks")
        .then((res) => {
            dispatch({
                type: types.GET_BANKS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_BANKS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getCustomers = () => async (dispatch) => {
    sif
        .get("projects")
        .then((res) => {
            dispatch({
                type: types.GET_CUSTOMERS,
                payload: res.data.content.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_CUSTOMERS,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getBanksBranches = (id) => async (dispatch) => {
    sif
        .get("banks/branches")
        .then((res) => {
            dispatch({
                type: types.GET_BANKS_BRANCHES,
                payload: res.data.content.filter((b)=> {return b.administrative_region_id === parseInt(id)} )
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_BANKS_BRANCHES_ERROR,
                payload: { message: err },
            });
        });
};

export const getProjects = (id) => async (dispatch) => {
    sif
        .get(  `projects/regions/${id}`)
        .then((res) => {
            dispatch({
                type: types.GET_PROJECTS,
                payload: res.data.content.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_PROJECTS_ERROR,
                payload: { message: err },
            });
        });
};




export const getPosts = () => async (dispatch) => {
    sif
        .get("posts")
        .then((res) => {
            dispatch({
                type: types.GET_POSTS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_POSTS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const changeLanguage = (payload) => {
    return {
        type: types.SET_LANG,
        payload,
    };
};

export const getMission = () => async (dispatch) => {
    sif
        .get("mission-vision")
        .then((res) => {
            dispatch({
                type: types.GET_MISSION,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_MISSION_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getGallery = () => async (dispatch) => {
    sif
        .get("galleries")
        .then((res) => {
            dispatch({
                type: types.GET_GALLERY,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_GALLERY_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getTerms = () => async (dispatch) => {
    sif
        .get("terms")
        .then((res) => {
            console.log(res)
            dispatch({
                type: types.GET_TERMS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_TERMS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getStatistics = () => async (dispatch) => {
    sif
        .get("statistics")
        .then((res) => {
            dispatch({
                type: types.GET_STATISTICS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_STATISTICS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getDirectories = () => async (dispatch) => {
    sif
        .get("company/directories")
        .then((res) => {
            dispatch({
                type: types.GET_DIRECTORIES,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_DIRECTORIES_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getCategories = () => async (dispatch) => {
    sif
        .get("categories")
        .then((res) => {
            dispatch({
                type: types.GET_CATEGORIES,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_CATEGORIES_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getContact = () => async (dispatch) => {
    sif
        .get("contact/info")
        .then((res) => {
            dispatch({
                type: types.GET_CONTACT,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_CONTACT_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};



export const getLanguages = () => async (dispatch) => {
    sif
        .get("languages")
        .then((res) => {
            localStorage.setItem('langs' , JSON.stringify(res.data.content))
            dispatch({
                type: types.GET_LANGS,
                payload: res.data.content
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_LANGS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};
