import React, {useEffect, useState} from 'react';
import Image from "../../../assets/img/Image";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import AboutFund from "./AboutFund/AboutFund";
import FundStructure from "./FundStructure/FundStructure";
import Leadership from "./Leadership/Leadership";
import Mission from "./Mission/Mission";
import {connect} from "react-redux";
import Partners from "./Partners/Partners";
import Reports from "./Reports/Reports";
import {getMission , getPartners} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function About(props) {
    const {t , i18n} = useTranslation();
    const [key, setKey] = useState('0');
    const trigger = 1;
    const {missions , getMission , getPartners , partners} = props

    useEffect(()=>{
        let mounted = true
        if (mounted){
            if (!partners.length|| i18n.language !== props.lang ){
                console.log('salam')
                getPartners()
            }
            props.match.params.id && setKey(props.match.params.id)
            if (!missions.length || i18n.language !== props.lang  ) {
                getMission()
            }
        }
        return function cleanup() {
            mounted = false
        }

    },[t])

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    return (
        <div className="main">
        <Helmet>
            <title>{t('site.EDF')}  - {t('menu.about')} </title>
        </Helmet>
         <div className="position-relative aboutTabs">
            <div className="container-fluid">
                <div className="mt-5 px-lg-5 px-md-0 px-0 pt-md-3">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        className={'d-lg-flex d-md-flex d-sm-flex d-block'}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="0" title={t('aboutTabs.aboutFundTab')} tabClassName="line-bottom my-0 mx-md-0 mx-2">
                            <AboutFund setKey={setKey} />
                        </Tab>
                        {missions?.length ? missions?.map((m , i)=>  (
                            // ${pad(i+2)}
                            <Tab eventKey={m.id} title={ `${m.locale.title.toLocaleUpperCase(localStorage.getItem('locale')) }` } tabClassName="line-bottom my-0 mx-md-0 mx-2">
                                <Mission
                                    title={m?.locale.title}
                                    description={m?.locale.description}
                                    items={m?.mission_vision_items}
                                />
                            </Tab>
                        )) : ' ' }
                        {/*<Tab eventKey="3" title={`${pad(missions.length+2)} FƏALİYYƏT İSTİQAMƏTLƏRİ`} tabClassName="line-bottom">*/}

                        {/*</Tab>*/}
                        {/*${pad(missions.length+3)}*/}
                        <Tab eventKey="4" title={t('aboutTabs.fundStructureTab')}  tabClassName="line-bottom my-0 mx-md-0 mx-2">
                            <FundStructure/>
                        </Tab>
                        {/*${pad(missions.length+4)}*/}
                        <Tab eventKey="5" title={t('aboutTabs.leadershipTab')}  tabClassName="line-bottom my-0 mx-md-0 mx-2">
                            <Leadership/>
                        </Tab>
                        {/*${pad(missions.length+5)}*/}
                        <Tab eventKey="6" title={t('aboutTabs.reportsTabs')} tabClassName="line-bottom my-0 mx-md-0 mx-2">
                            <Reports/>
                        </Tab>
                        {/*${pad(missions.length+6)}*/}
                        {partners?.title ?
                        <Tab eventKey="7"  title={`${partners?.title?.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}`} tabClassName="line-bottom my-0 mx-md-0 mx-2">
                            <Partners/>
                        </Tab> : null
                        }
                    </Tabs>
                </div>
            </div>
             <div>
                <img src={Image.Map} alt="" className="bg-image"/>
            </div>
        </div>
        </div>
    );
}


const mapStateToProps = ({ missions , partners  , lang}) => {
    return {missions , partners , lang};
};

export default connect(mapStateToProps, { getMission  , getPartners})(About);

