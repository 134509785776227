import React, {useRef , useState , useEffect} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import history from "../../../const/history";
import api from "../../../const/api";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getLocation, getProductList} from "../../../redux/actions";

function NewsAndAnnouncement(props) {
    const { t } = useTranslation();
    let [trigger, setTrigger] = useState(0);
    const [warn , showWarn] = useState(false)
    const [postlist , setPostList] = useState();
    const [spin , setSpin] = useState(false);
    const {getLocation  , locations} = props
    const getPostList = () => {
        setSpin(true)
        api.get(`posts` ,{ params: { category_id: props.match.params.id } }).then((res) => {
            res.data.content && setSpin(false)
            setPostList(res.data.content);
        });
    };

    useEffect(()=>{
     let mounted = true;
         if(mounted){
             props?.products?.length === 0 && props.getProductList()
         }
        return function cleanup() {
            mounted = false
        }
    })


    const createUrl = (l) => {
        let [type , relational_id , action_url , data] = [l?.type, l?.relational_id, l?.action_url , l?.data]
        switch (type){
            case 1:
                return `/posts/full/${relational_id}`;
            case 2:
                return `/about/1`;
            case 3:
                return `/credit-organization`;
            case 4:
                return `/credit-organization`;
            case 5:
                return `regions/banks/${relational_id}`;
            case 6:
                return {pathname: `/posts`, state: { id: relational_id ,  name: l.data.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}};
            case 7:
                let product = props.product.find(p => p.id === parseInt(relational_id))
                return {
                    pathname: `/credit-content/${relational_id}`,
                    state: { name: product?.locale?.name , key: undefined }
                };
            case 8:
                let pr = props.product.find(p => p.id === parseInt(relational_id))
                return {
                    pathname: `/credit-content/${relational_id}`,
                    state: { name: pr?.locale?.name  ,  key: undefined}
                };
            case 9:
                return `/about/4`;
            case 10:
                return `/contact`;
            case 11:
                return `/about/5`;
            case 12:
                return `/faq`;
            case 13:
                return `/faq`;
            case 14:
                return `/gallery`;
            case 15:
                return `/instructions`;
            case 16:
                return `/about/7`;
            case 17:
                return `road-map`;
            case 18:
                return `road-map`;
            case 19:
                return `docs/0`;
            case 20:
                return `/about/${relational_id}`;
            case 21:
                return `/about/1`;
            case 23:
                let prd = props.product.find(p => p.id === parseInt(relational_id))
                return {
                    pathname: `/credit-content/${relational_id}`,
                    state: { name: prd?.locale?.name , key: undefined }
                };
            case 22:
                let prdc = props.product.find(p => p.id === parseInt(relational_id))
                return {
                    pathname: `/credit-content/${relational_id}`,
                    state: { name: prdc?.locale?.name  , key: 'priority'}
                };
            case 24:
                let prdct = props.product.find(p => p.id === parseInt(relational_id))
                return {
                    pathname: `/credit-content/${relational_id}`,
                    state: { name: prdct?.locale?.name  , key: 'documents'}
                };
            case 25:
                return `/regions/projects/${relational_id}`;
            case 26:
                return '/about/6';
            case 27:
                return '/about/6';
            case 28:
                return action_url;
            default:
                return '/' ;
        }
        // public const POST = 1;
        // public const ABOUT_COMPANY = 2;
        // public const ADMINISTRATIVE_REGIONS = 3;
        // public const BANKS = 4;
        // public const BANK_BRANCH = 5;
        // public const CATEGORIES = 6;
        // public const PRODUCT_CLASSIFICATION = 7;
        // public const PRODUCT_CLASSIFICATION_PROPERTIES = 8;
        // public const COMPANY_STRUCTURE = 9;
        // public const CONTACT_INFORMATION = 10;
        // public const DIRECTORY = 11;
        // public const FAQ = 12;
        // public const FAQ_PAGE = 13;
        // public const GALLERY = 14;
        // public const INSTRUCTION = 15;
        // public const INTERNATIONAL_PARTNERS = 16;
        // public const INVESTOR_ROADMAP = 17;
        // public const INVESTOR_ROADMAP_PROPERTY = 18;
        // public const LEGISLATION = 19;
        // public const MISSION_VISION = 20;
        // public const MISSION_VISION_ITEM = 21;
        // public const PRODUCT_PRIORITY = 22;
        // public const PRODUCT = 23;
        // public const PRODUCT_DOCUMENT = 24;
        // public const PROJECTS = 25;
        // public const REPORTS = 26;
        // public const REPORT_ITEMS = 27;
        // public const STATIC_PAGES = 28;
    }



    useEffect(() => {
        if(props.location.state?.name){
            getLocation(props.location.state.name)
        }
        if ( props.match.params.id ){
            getPostList();
        }
        if(props.location.state?.results){
            setPostList(props.location.state?.results);
            // eslint-disable-next-line no-unused-expressions
            !props.location.state?.results.length ?  showWarn(true) : showWarn(false)
        }
        if(!props.match.params.id && !props.location.state?.results){
            history.push('/')
        }
        if (!props.location.state?.results){
            history.push('/')
        }
    }, [trigger , t]);


    const redirect = ()=>{
        if(props.location.state.results){
            window.history.back();
        }
        if(props.location.state.name) {
            history.push('/')
        }
    }

    const news = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed: 1000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed:2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };
    const next = () => {
        news.current.slickNext();
    }
    const previous = () => {
        news.current.slickPrev();
    }
    return (
        <div className="main">
            <div className="position-relative">
                {!spin &&
                <div className="container-fluid animated fadeIn">
                    <div className=" mt-lg-2 mt-md-3 mt-sm-5 mt-5 pt-5">
                        <div className="row align-items-center ">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="d-flex align-items-center">
                                    <Link onClick={redirect} className="about-back">
                                        <img src={Image.Back} alt=""/>
                                    </Link>
                                    <div className="page-head p-0">
                                        <p> {props.location.state?.results ? t('posts.searchResults') : locations}</p>
                                    </div>
                                </div>
                                {/*<div className="page-text">*/}
                                {/*    {*/}
                                {/*        !props.location.state?.results &&*/}
                                {/*        <p>{t('gallery.galleryText')}</p>*/}
                                {/*    }*/}
                                {/*</div>*/}

                            </div>

                            <div className="arrows ml-lg-4 ml-md-0 ml-sm-0 ml-0 mt-lg-5 mt-md-5 mt-sm-5 mt-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start p-0">
                                <Link onClick={() => {previous()}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                </Link>
                                <Link onClick={() => {next()}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                </Link>
                            </div>
                        </div>
                        {!spin &&
                        <div className="col-10 ml-2 position-relative search client-img p-0">
                            <div className="row">
                                {postlist?.map((l , i)=>(
                                    <Link
                                        to={createUrl(l)}
                                        dangerouslySetInnerHTML={{
                                            __html: l.data
                                        }}
                                        key={i} className="col-md-12 searchBox nav-link text-dark cursor-pointer mb-3 px-5 font-weight-bold faq-card  col-12" />
                                ))}
                            </div>
                            {warn &&
                            <h5>{t('posts.searchError')}</h5>
                            }
                        </div>
                        }
                    </div>
                </div>
                }
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ locations , product }) => {
    return {  locations , product };
}

export default connect(mapStateToProps, {  getLocation , getProductList })(NewsAndAnnouncement);
