import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {combineReducers} from "redux";
import {
    toggleReducer,
    productReducer,
    langReducer,
    legistationReducer,
    roadMapReducer,
    LocationReducer,
    aboutReducer,
    categoryReducer,
    contactInfo,
    directoryReducer,
    statisticsReducer,
    galleryReducer,
    termReducer,
    postReducer,
    bankReducer,
    hoverReducer,
    customersReducer,
    instructionsReducer,
    bankBranchReducer,
    missionsReducer,
    faqsReducer,
    faqDataReducer,
    projectReducer,
    acceptReducer,
    partnerReducer,
    partnerPage,
    reportsReducer,
    reportItemsReducer, languageReducer
} from "./reducers";


const rootReducer = combineReducers({ 
    toggleReducer,
    product:productReducer,
    legistation: legistationReducer,
    roadMap: roadMapReducer,
    lang : langReducer,
    locations:LocationReducer,
    about: aboutReducer,
    categories:categoryReducer,
    contactInfo: contactInfo,
    directory: directoryReducer,
    statistics: statisticsReducer,
    galleries: galleryReducer,
    terms: termReducer,
    posts: postReducer,
    banks: bankReducer,
    customers: customersReducer,
    hover: hoverReducer,
    instructions: instructionsReducer,
    bankBranches : bankBranchReducer,
    missions : missionsReducer,
    faqs : faqsReducer,
    faqsData : faqDataReducer,
    projects:projectReducer,
    accepted: acceptReducer,
    partners:partnerReducer,
    reportItem: reportItemsReducer,
    langs: languageReducer
  });

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;
