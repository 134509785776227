import React, {useEffect, useState} from 'react';
import Image from "../../../assets/img/Image";
import {Link} from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'
import CreditClassification from "./CreditClassification/CreditClassification";
import Documents from "./Documents/Documents";
import Priority from "./Priority/Priority";
import Mechanism from "./Mechanism/Mechanism";
import Branches from "./Branches/Branches";
import Legislation from "./Legislation/Legislation";
import Tab from 'react-bootstrap/Tab'
import api from "../../../const/api";
import CreditCalculator from "./CreditCalculator/CreditCalculator";
import { connect } from "react-redux";
import {getProductList , getLocation} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import sif from "../../../const/api"
import RoadMap from '../../Pages/RoadMap/RoadMap'
function Credit(props) {
    const {t , i18n} = useTranslation();
    const {id} = props.match.params;
    const [product , setproductList] = useState({})
    const [length , setLength] = useState(0)

    const {state} = props.location;
    const { getLocation  } = props;
    const trigger = 1
    const  getProductById = (id) => {
      sif.get(`products/${id}`).then((res)=>{
        setproductList(res.data.content)
        setLength(res.data.content?.classifications?.length)
      }).catch((err)=>{
          console.log(err)
      })
    }
    useEffect(()=>{
        console.log(props.location.state);
        props.location.state?.key && setKey(props.location.state?.key)
        let mounted = true
         if (mounted) {
             getProductById(id)
             getLocation(state.name)
         }
        return function cleanup() {
            mounted = false
        }
    },[trigger , state , t])


    const [key, setKey] = useState('mechanism');
    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')}  - {t('menu.products').toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}</title>
            </Helmet>
            <div className="position-relative">
               <div className="animated fadeIn container-fluid">
                   <Link to="/products" className="about-back mt-lg-4 mt-md-5 mt-sm-5 mt-5 pt-lg-5 pt-md-3 pt-sm-2 pt-3">
                       <img src={Image.Back} alt=""/>
                   </Link>
                    <div className="pt-5 mt-lg-0 mt-md-5 mt-sm-5 mt-5 tabs">
                        <Tabs
                            className="justify-content-sm-between justify-content-end "
                            id="controlled-tab-example"
                            activeKey={props.location.state.key && key}
                            onSelect={(k) => {
                                setKey(k);
                                console.log(k)
                            } }
                        >
                            {product?.classifications?.length > 0 &&
                                product?.classifications?.filter(s=> s?.is_active === 1).map((s , i)=>(
                                    <Tab eventKey={'classifications'} title={s.locale.tab_name} tabClassName="line-bottom">
                                         <CreditClassification product={product}  postList={s} id={id}/>
                                    </Tab>
                                ))
                            }

                            {product?.show_priority ?
                                <Tab eventKey={'priority'} title={t('creditContentTabs.priorityTab')} tabClassName="line-bottom">
                                    <Priority product={product} postList={product.priorities} id={id} />
                                </Tab> : undefined
                            }

                            {product?.show_document ?
                            <Tab eventKey={'documents'} title={t('creditContentTabs.documentsTab')} tabClassName="line-bottom">
                                <Documents product={product} postList={product?.documents} id={id}   />
                            </Tab> : undefined
                            }
                            {product?.show_chart ?
                            <Tab eventKey={'mechanism'} title={t('creditContentTabs.mechanismTab')} tabClassName="line-bottom">
                                <Mechanism id={id}/>
                            </Tab> : undefined
                            }
                            {product?.show_map ?
                                <Tab eventKey={'branches'} title={t('creditContentTabs.branchesTab')} tabClassName="line-bottom">
                                    <Branches id={id}  />
                                </Tab> : undefined
                            }
                            {product?.show_calculator ?
                                <Tab eventKey={'4'} title={t('creditContentTabs.creditCalculatorTab')} tabClassName="line-bottom">
                                    <CreditCalculator/>
                                </Tab> : undefined
                            }
                            {product?.show_roadmap ?
                                <Tab eventKey={'roadmap'} title={t('creditContentTabs.legislationTab')} tabClassName="line-bottom">
                                       <RoadMap intab={true}  />
                                </Tab> : undefined
                            }
                        </Tabs>
                    </div>

                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ product, lang }) => {
    return { product, lang };
}

export default connect(mapStateToProps, { getProductList , getLocation })(Credit);
