import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import Slider from "react-slick";
import {connect} from "react-redux";
import {getProductList} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function Products(props) {
    const {t , i18n} = useTranslation();
    const {getProductList , product} = props
    const trigger = 1
    useEffect(()=>{
        if (product.length === 0 || i18n.language !== props.lang ){
            getProductList()
        }
    },[trigger , t])

    const products = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 4 ,
        slidesToScroll: 1,
        arrows : false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                }
            },

        ]
    };
    const next = () => {
        products.current.slickNext();
    }
    const previous = () => {
        products.current.slickPrev();
    }
    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')} - {t('products.title').toUpperCase()}</title>
            </Helmet>
             <div className="position-relative">
             <div className="container-fluid">
                {product.length !== 0 &&
                 <div className="row animated fadeIn  mt-5 pt-lg-0 pt-md-5 pt-sm-5 pt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="row ml-0 page-head">
                            <p>{t('products.title')}</p>
                        </div>
                    </div>
                    {product.length !== 0 &&
                    <>
                        <div className="row client-links justify-content-end align-items-center p-0 col-lg-4 col-md-4 col-sm-5 col-5 mt-lg-5 mt-md-3 mt-sm-2 mt-0 pt-lg-3 pt-md-2 pt-sm-0 pt-0">
                            {/*<div className="transition p-0">*/}
                            {/*    <Link to="/credit" className="d-flex line align-items-center">*/}
                            {/*        <p>Daha ətraflı</p>*/}
                            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="17.066" height="12" viewBox="0 0 17.066 12"><path d="M11.492,4.854a.605.605,0,0,0-.86.851l4.361,4.361H.6a.6.6,0,0,0-.6.6.606.606,0,0,0,.6.611H14.993l-4.361,4.353a.617.617,0,0,0,0,.86.6.6,0,0,0,.86,0L16.886,11.1a.592.592,0,0,0,0-.851Z" transform="translate(0 -4.674)" fill="black"/></svg>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            {product.length >= 5 &&
                            <div className="service-link  ml-2 mt-lg-0 mt-md-2 mt-sm-3 mt-3 pl-5 d-flex">
                                <div>
                                    <Link onClick={()=>{previous()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                    </Link>
                                    <Link  onClick={()=>{next()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                    </Link>
                                </div>
                            </div>}
                        </div>
                        <div className="col-11">
                            {(product.length && product?.length >= 5) ?
                               <div className="product-slider">
                                <div className="product">
                                    <Slider
                                        className='mainslider'
                                        ref={slider => (products.current = slider)}
                                        {...settings}
                                    >
                                        {product?.length && product?.map((e, i)=>(
                                            <Link
                                                to={{
                                                    pathname: `/credit-content/${e.id}`,
                                                    state: { name: e.locale.name }
                                                }}
                                                key={i} className="box position-relative"
                                            >
                                                <img src={e?.picture?.path} alt=""/>
                                                <div className="lay"></div>
                                                <div className="about-product">
                                                    <p>{e.locale.name}</p>
                                                    <div className={'mb-2'}>{e.locale.description}</div>
                                                </div>
                                            </Link>
                                        ))}
                                    </Slider>
                                </div>
                            </div>:
                            <div className={'container-fluid product-slider'}>
                                <div className="product" style={{overflow:'unset'}}>
                                    <div className="row">
                                        {product?.length > 0 && product?.map((e, i)=>(
                                            <div className="col-xl-3 col-lg-6 col-sm-12 col-12 box   mt-sm-4 mt-4">
                                                <Link
                                                    to={{
                                                        pathname: `/credit-content/${e.id}`,
                                                        state: { name: e?.locale?.name }
                                                    }}
                                                    key={i} className="w-100 h-100 d-block px-0 position-relative"
                                                >
                                                    <img src={e?.picture?.path} alt=""/>
                                                    <div className="lay"></div>
                                                    <div className="about-product">
                                                        <p>{e.locale.name}</p>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: e.locale.description
                                                            }}
                                                            className={'mb-2'}/>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </>
                    }
                </div>
                }
            </div>
            <div>
                <img src={Image.Map} alt="" className="bg-image"/>
            </div>

        </div>
        </div>
    );
}

const mapStateToProps = ({ product  , lang}) => {
    return { product , lang };
}

export default connect(mapStateToProps, { getProductList  })(Products);
