import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import sif from "../../../const/api";
function ContactForm(props) {
    const {t} = useTranslation()
    const [name , setName] = useState('');
    const [email , setEmail] = useState('')
    const [text ,setText] = useState('')
    const [showSuccess , setshow] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [mailError,SetMailError] = useState(false);
    const [textError , setTextError] = useState(false);
    const showmessage = (set) =>{
        set(true)
        setTimeout(function(){set(false) }, 1000);
    }

    const  inputs = [
        {n: name , error: nameError , setError : setNameError , setValue : setName , placeholder: t('contact.name') ,type:'text'},
        {n: email , error: mailError ,  setError : SetMailError , setValue : setEmail , placeholder: t('contact.mail') ,type:'email'},
        {n: text ,error: textError ,  setError : setTextError , setValue : setText ,  placeholder: t('contact.message') , type:'text'},
    ]

    const onsubmit = (e)=>{
        e.preventDefault()
        inputValidation()
    }

    const sendMessage = () =>{
        sif
            .post("contact/us", {
                name,
                email,
                description: text

            })
            .then((res) => {
                showmessage(setshow)
                setName('');
                setText('');
                setEmail('');
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const inputValidation = () =>{
        inputs.map((i) =>{
            if (i.n.trim()==null || i.n.trim()==""|| i.n===" ") {showmessage(i.setError)}
        })
        sendMessage()
    }


    return (
        <>
            <form onSubmit={onsubmit}>
                <h4 className="mb-4">{t('contact.contactUs')}</h4>
                {showSuccess && <p className="text-success animated fadeIn">{t('contact.successMessage')}</p> }

                {inputs.map((i)=>(
                   <>
                       <input value={i.n} className={`${i.error ? 'error' : 'mb-4'}`} type={i.type} onChange={(e)=>{i.setValue(e.target.value)}} placeholder={i.placeholder}/>
                       {i.error &&  <span className=" animated fadeIn errortext text-danger">{t('contact.inputError')}</span> }
                   </>
                ))}

                <div className={`mt-4`}>
                    <button type='submit' className="button font-weight-bold">
                        {t('contact.send')}
                    </button>
                </div>
            </form>
        </>
    );
}

export default ContactForm;
