import React, {useEffect, useState} from 'react';
import Image from "../../../assets/img/Image";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {connect} from "react-redux";
import {getCategories} from "../../../redux/actions";
import PostPage from "./PostPage";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

const Posts = (props) => {
    const {t , i18n} = useTranslation()
    const [key, setKey] = useState('1');
    const {categories, getCategories} = props;

    useEffect(() =>{
        if (categories.length === 0 || i18n.language !== props.lang) {
            getCategories()
        }
        if (props.location.state?.id){
            setKey(props.location.state?.id)
        }
        else{
            setKey(categories[0]?.id)
        }
    }, [props.location?.state?.id , t])


    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')}  - { t('menu.news').toLocaleUpperCase(localStorage.getItem('locale').toUpperCase()) }</title>
            </Helmet>
            <div className="position-relative aboutTabs">
                <div className="container-fluid">
                    <div className="col-lg-11 col-md-11 margin col-sm-12 col-12 mt-5 px-2">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            className="justify-content-sm-between justify-content-end "
                            // className={'mt-4 d-md-flex justify-content-around d-block'}
                            onSelect={(k) => {setKey(k)} }
                        >
                            {
                                categories.filter( (c) =>{return c.status === 1} ).map((c , i)  => (
                                    <Tab eventKey={c.id} title={c.locale.name} tabClassName="mx-3 line-bottom">
                                        <PostPage name={c.locale.name} id={c.id} />
                                    </Tab>
                                ) )
                            }
                        </Tabs>
                    </div>
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ categories , lang }) => {
    return {categories , lang}
}
export default connect(mapStateToProps, { getCategories })(Posts);

