import React, {useState , useEffect} from 'react';
import sif from "../../../const/api";
import Image from "../../../assets/img/Image";
import moment from "moment";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import {useTranslation} from "react-i18next";

function FormApply(props) {
    const {t} = useTranslation()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [passportNumber, setPassportNumber] = useState('')
    const [firstRequest, setFirstRequest] = useState(false)
    const [requestPurpose, setRequestPurpose] = useState('')
    const [requestedPerson, setRequestedPerson] = useState('')
    const [date, setDate] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [nameError, setNameError] = useState(false)
    const [capthcaError, setCapthaError] = useState(false)
    const [mailError, setMailError] = useState(false)
    const [passportError, setPassportError] = useState(false)
    const [firstRequestError, setFirstRequestError] = useState(false)
    const [requestPurposeError, setRequestPurposeError] = useState(false)
    const [requestedPersonError, setRequestedPersonError] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [showSuccess, setshow] = useState(false);
    const [persons, setPersons] = useState([]);


    useEffect(() =>{
        loadCaptchaEnginge(6 , 'darkBlue' , 'white');
    } ,[capthcaError])

    useEffect(()=>{
        sif.get('head/persons').then((res)=>{
            setPersons(res.data.content)
        })
    } ,[])

    const showmessage = (set) => {
        set(true)
        setTimeout(function () {
            set(false)
        }, 1000);
    }

    const inputs = [
        {
            n: name,
            error: nameError,
            setError: setNameError,
            setValue: setName,
            placeholder: t('videoApply.name'),
            type: 'text'
        },
        {
            n: email,
            error: mailError,
            setError: setMailError,
            setValue: setEmail,
            placeholder:  t('videoApply.email'),
            type: 'email'
        },

        {
            n: address,
            error: addressError,
            setError: setAddressError,
            setValue: setAddress,
            placeholder:  t('videoApply.address'),
            type: 'text'
        },
        {
            n: passportNumber,
            error: passportError,
            setError: setPassportError,
            setValue: setPassportNumber,
            placeholder: t('videoApply.passportNumber'),
            type: 'text'
        },
        {
            n: phone,
            error: phoneError,
            setError: setPhoneError,
            setValue: setPhone,
            placeholder: t('videoApply.phone'),
            type: 'tel'
        },
        {
            n: firstRequest,
            error: firstRequestError,
            setError: setFirstRequestError,
            setValue: setFirstRequest,
            placeholder: t('videoApply.firstRequest'),
            type: 'radio'
        },
        {
            n: requestPurpose,
            error: requestPurposeError,
            setError: setRequestPurposeError,
            setValue: setRequestPurpose,
            placeholder: t('videoApply.requestPurpose'),
            type: 'text'
        },
        {
            n: requestedPerson,
            error: requestedPersonError,
            setError: setRequestedPersonError,
            setValue: setRequestedPerson,
            placeholder:  t('videoApply.requestedPerson'),
            type: 'select'
        },
        {
            n: date,
            error: dateError,
            setError: setDateError,
            setValue: setDate,
            placeholder:  t('videoApply.date'),
            type: 'datetime-local'
        },
    ]

    const onsubmit = (e) => {
        e.preventDefault()
        inputValidation()
    }
    const sendMessage = () => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha_value , false)==true) {
            sif
                .post("meetings", {
                    name: name,
                    email: email,
                    passport_number: passportNumber,
                    request_purpose: requestPurpose,
                    head_person_id: requestedPerson,
                    date_for_meeting: moment(date).format("YYYY-MM-DD hh:mm"),
                    phone: phone,
                    address: address,
                    is_first_request: firstRequest,
                })
                .then((res) => {
                    showmessage(setshow)
                    inputs.map((i) => {
                        if (i.type !== 'radio'){
                            i.setValue(' ')
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                });
        }
        else {
            showmessage(setCapthaError)
        }
    }

    const inputValidation = () => {
        inputs.map((i) => {
            if (i.type !== 'radio'){
                if (i.n.trim() == null || i.n.trim() == "" || i.n === " ") {
                    showmessage(i.setError)
                }
            }
        })
        sendMessage()
    }

    return (
        <>
            <div className="apply-form mt-0 form w-100">
                <form onSubmit={onsubmit}>
                    {showSuccess &&
                    <p className="text-success animated fadeIn">{t('videoApply.inputs.success')}</p>}

                    <div className="row">
                        {inputs.map((i) => (
                            <>
                                {i.type === 'radio' ?
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="d-flex align-items-baseline m-0 input-radio pl-2">
                                            <p className="mr-5">{t('videoApply.firstRequest')}</p>
                                            <div onClick={()=>{i.setValue(true)}} className="d-flex  cursor-pointer align-items-center mx-4">
                                                <span className={i.n === true && 'active'}></span>
                                                {t('videoApply.yes')}
                                            </div>
                                            <div  onClick={()=>{i.setValue(false)}} className="d-flex cursor-pointer align-items-center mx-4">
                                                <span className={i.n === false && 'active'}></span>
                                                {t('videoApply.no')}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    i.type === 'select' ?
                                        <div className="col-md-6 col-12">
                                            <div className="select">
                                                <select
                                                    onChange={(e) => {
                                                        i.setValue(e.target.value)
                                                    }}
                                                    className={`${i.error ? 'error' : 'mb-4'}`}
                                                    name={'head_person_id'}>
                                                    <option  className="d-none">{i.placeholder}</option>
                                                    {persons.map((p , i)=>(
                                                        <option key={i} value={p.id}>{p.locale.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {i.error && <span className=" animated fadeIn errortext text-danger">{t('contact.inputError')}</span>}
                                        </div>
                                        :
                                    <div className="col-md-6 col-12">
                                        <input value={i.n} className={`${i.error ? 'error' : 'mb-4'}`} type={i.type}
                                               onChange={(e) => {
                                                   i.setValue(e.target.value)
                                               }} placeholder={i.placeholder}/>
                                        {i.error && <span className=" animated fadeIn errortext text-danger">{t('contact.inputError')}</span>}
                                    </div>
                                }
                            </>
                        ))}
                    </div>
                    <div className={"apply-transition d-flex align--center justify-content-center"}>
                        <div className="pr-2 pt-2">  <LoadCanvasTemplateNoReload className={'simpleCaptcha'} /></div>
                        <div className={'pr-2'}>
                            <input className={'px-2'} id={'user_captcha_input'}  type="text" placeholder={t('videoApply.addCaptcha')} />
                            <span className={`${ capthcaError ? 'd-block' : 'd-none'  } animated fadeIn errortext text-danger`} > {t('videoApply.errorCaptcha')}</span>
                        </div>
                        <button to="/apply-form" className="d-flex mt-2 h-100 align-items-center">
                            {t('videoApply.send')}<img className="ml-3" src={Image.RightArrow} alt=""/>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}


export default FormApply;

