import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import Map from "../../../Elements/Map/Map";
import {connect} from "react-redux";
import {getBanks, getLocation} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";

function Branches(props) {
    const {t} = useTranslation();
    const branches = useRef();
    const settings = {
        dots: false,
        padding:'20px',
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 50000,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows : false,
        rows: 2,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 3,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };
    const next = () => {
        branches.current.slickNext();
    }
    const previous = () => {
        branches.current.slickPrev();
    }

    const { getBanks, banks } = props
    const trigger = 1
    useEffect(()=>{
        let mounted = true
        if (mounted){
            if (!banks.length || props.lang){
                getBanks()
            }
        }
        return function cleanup() {
            mounted = false
        }
    },[trigger , t])

    const FilterStatus = (limit) =>{
        if (banks.length){
            let newBanks = banks.filter((e)=>{
                return e.limit_exist === limit
            })
        }
    }

    return (
        <div className="row pt-4  animated fadeInUp">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className={'pl-4 mt-lg-2 mt-5  responsive-map'} >
                    <Map type={'banks'}/>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="branch-link">
                    <p>{t('branches.branchesTitle')}</p>
                </div>
                <div className="service-link justify-content-end ml-2 mt-lg-4 mt-md-2 mt-sm-3 mt-3 pl-5 d-flex">
                    <div>
                        <span className={'mr-2'} onClick={()=>{previous()}} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                        </span>
                        <span  onClick={()=>{next()}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                        </span>
                    </div>
                </div>
               <div className="branches-slider" style={{marginBottom: 100}}>
                   <div className="banks">
                       <Slider
                           className='mainslider'
                           ref={slider => (branches.current = slider)}
                           {...settings}
                       >
                           {
                               banks.map((banks, index) => (
                                   <div key={index} className="mainCont">
                                       <div className="position-relative">
                                           <img src={banks.picture.path} alt=""/>
                                           {
                                               banks.limit_exists === 1 ?
                                                   <p>{t('branches.limitAvailable')}</p>
                                                   :
                                                   <p className={"bg-danger"}>{t('branches.noLimit')}</p>
                                           }
                                       </div>

                                   </div>
                               ))
                           }
                       </Slider>
                   </div>
               </div>
            </div>
        </div>

    );
}

const mapStateToProps = ({ banks }) => {
    return {  banks };
}

export default connect(mapStateToProps, { getLocation, getBanks })(Branches);
