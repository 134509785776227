import React from 'react';
import Image from "../../../assets/img/Image";

function SocialElement(props) {
    const {src , href} = props
    return (
        <a target={"_blank"} href={href} >
            <img src={src} alt=""/>
        </a>
    );
}


export default SocialElement;
