import React from 'react';

function Loan(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38.011" height="35.395" viewBox="0 0 38.011 35.395"><g transform="translate(0 0)"><path d="M36.893,237.318a3.628,3.628,0,0,0-5.079.057l-6.073,6.112H23.356a3.609,3.609,0,0,0-3.332-4.99h-8.2a7.891,7.891,0,0,0-5.619,2.329L0,247.2v9.58H5.566l5.717-4.9H27.976l8.981-9.374a3.631,3.631,0,0,0-.065-5.179Zm-1.52,3.614-8.348,8.712H10.459l-5.717,4.9H2.227V248.1l5.562-5.707a5.678,5.678,0,0,1,4.038-1.671h8.2a1.381,1.381,0,0,1,0,2.763H12.683v2.227H26.667l6.724-6.767a1.408,1.408,0,0,1,1.96-.021,1.4,1.4,0,0,1,.021,2.007Z" transform="translate(0 -221.381)" fill="#4d4332"/><path d="M477.13,751.083a8.2,8.2,0,0,1,6.852,9.823h-2.225a6.043,6.043,0,0,0-4.627-7.635v5.7l-2.21,1.111v-6.833a6.043,6.043,0,0,0-4.748,7.658h-2.225a8.2,8.2,0,0,1,6.973-9.84v-1.029l2.21-1.111Z" transform="translate(-454.278 -748.926)" fill="#4d4332" fill-rule="evenodd"/></g></svg>
    );
}


export default Loan;
