import Logo from './logo.svg'
import Search from './search.svg'
import OpenMenu from './open-menu-icon.svg'
import Close from  './close.svg'
import Manat from './manat.svg'
import WhiteManat from './manat-white.svg'
import BlackManat from './black-manat.svg'
import MainLogo from './main-logo.png'
import Chat from './chat.png'
import Arrow from './arrow.svg'
import RightArrow from './right-arrow.svg'
import videoImg from './img.png'
import RightArrowBronze from './right-arrow-bronze.svg'
import Star from './star.svg'
import Man from './man.png'
import Girl from './girl.png'
import Document from './document.png'
import Map from './map.svg'
import AzeMap from './aze-map.png'
import IntBank from './interbank.svg'
import UniBank from './unibank.svg'
import AzerTurk from './azerturk.svg'
import BtbBank from './btbbank.svg'
import Office from './office.jpg'
import Last from './last.svg'
import Prev from './next.svg'
import Facebook from './facebook.svg'
import Instagram from './instagram.svg'
import Twitter from './twitter.svg'
import Youtube from './youtube.svg'
import Linkedin from './linkedin.png'
import Video from './img_2.png'
import Quote from './left-quote.svg'
import Google from './Google_logo.svg'
import Blender from './Blender_logo.svg'
import Kaspersky from './kaspersky.svg'
import Duracell from './duracell.svg'
import PG from './PG_logo.svg'
import Exonmobil from './exonmobil.svg'
import Oracle from './Oracle_logo.svg'
import Diebold from './diebold-2.svg'
import Pallete from './pallete.svg'
import Block from './block.svg'
import Playicon from './play-icon.svg'
import Play from './play.svg'
import Products1 from './products-1.svg'
import Products2 from './products-2.svg'
import Products3 from './products-3.svg'
import Products4 from './products-4.svg'
import Next from './next-arrow.svg'
import Back from './back-track.svg'
import Microphone from './microphone.svg'
import GoogleMap from './googlemap.svg'
import Baku from './baku.jpg'
import About1 from './about-img1.svg'
import About2 from './about-img2.svg'
import About3 from './about-img3.svg'
import About4 from './about-img4.svg'
import Photo1 from './photo1.jpg'
import Download from './download.png'
import Photo2 from './photo2.jpg'
import Photo3 from './photo3.jpg'
import Photo4 from './photo4.jpg'
import Photo6 from './photo6.jpg'
import Photo7 from './photo7.jpg'
import Photo8 from './photo8.jpg'
import News1 from './news1.jpg'
import News2 from './news2.jpg'
import News3 from './news3.jpg'
import News4 from './news4.jpg'
import OnlineChat from './video-camera.svg'
import Link from './link.svg'
import Bank1 from './bank1.png'
import Bank2 from './bank2.png'
import Bank3 from './bank3.png'
import Bank4 from './bank4.png'
import Bank5 from './bank5.png'
import Bank6 from './bank6.png'
import Region from './region.svg'
import Navigation from './navigation.png'
import Location from './location.png'
import Phone from './phone-call.png'
import UpGreen from './up-green.svg'
import DownRed from './down-red.svg'
import Static1 from './static1.svg'
import Static2 from './static2.svg'
import Sedr from './sedrr.png'
import Muavin from './muavin.svg'
import Muavin2 from './muavin2.svg'
import Structure from './structure.png'
import HEfund  from  './partners/H.E.Fondu.jpg'
import Iqt from  './partners/Iqtisadiyyat_nazirliyil.jpg'
import IEliyev from  './partners/I.Eliyev.jpg';
import MEliyeva from './partners/M.Eliyeva.jpg';
import Qarabag from  './partners/Qarabag.jpg';
import HEliyev from  './partners/H.Eliyev.jpg'
import Mechanism from "./mechanism.png";
import Mission from './mission.png';
import Vision from './vission.png'
import Values from './values.png'
import Honesty from './honesty.png'
import Professionalizm from './professionalizm.png'

import Transparency  from './transparency.png';
import Employee from './employee.png'

export default  {
    Transparency,
    Employee,
    Honesty,
    Professionalizm,
    Vision,
    Values,
    Mission,
    Mechanism,
    Logo,
    Search,
    OpenMenu,
    Manat,
    WhiteManat,
    BlackManat,
    MainLogo,
    Chat,
    Arrow,
    RightArrow,
    RightArrowBronze,
    Star,
    Man,
    Girl,
    Document,
    Map,
    AzeMap,
    Video,
    IntBank,
    UniBank,
    AzerTurk,
    BtbBank,
    Office,
    videoImg,
    Last,
    Prev,
    Facebook,
    Linkedin,
    Instagram,
    Twitter,
    Youtube,
    Playicon,
    Quote,
    Google,
    Blender,
    Kaspersky,
    Duracell,
    PG,
    Exonmobil,
    Oracle,
    Diebold,
    Pallete,
    Block,
    Play,
    Products1,
    Products2,
    Products3,
    Products4,
    Close,
    Next,
    Back,
    Microphone,
    GoogleMap,
    Baku,
    About1,
    About2,
    About3,
    About4,
    Photo1,
    Photo2,
    Photo3,
    Photo4,
    Photo6,
    Photo7,
    Photo8,
    News1,
    News2,
    News3,
    News4,
    OnlineChat,
    Link,
    Bank1,
    Bank2,
    Bank3,
    Bank4,
    Bank5,
    Bank6,
    Region,
    Navigation,
    Location,
    Phone,
    UpGreen,
    DownRed,
    Static1,
    Download,
    Static2,
    Sedr,
    Muavin,
    Muavin2,
    Structure,
    HEfund,
    Iqt,
    IEliyev,
    MEliyeva,
    Qarabag,
    HEliyev,
}
