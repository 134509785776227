import React, {useEffect, useRef, useState} from 'react';
import Image from "../../../assets/img/Image";
import Form from 'react-bootstrap/Form'
import {Link} from "react-router-dom";
import Slider from "react-slick";
import api from "../../../const/api";
import {connect} from "react-redux";
import {getProductList} from "../../../redux/actions";
import {useTranslation} from "react-i18next";

function Credit(props) {
    const {getProductList , product} = props
    const trigger = 1
    const { t ,i18n} = useTranslation();

    useEffect(()=>{
        let mounted = true
        if (mounted){
            if (!product.length ||  i18n.language !== props.lang){
                getProductList()
            }
        }
        return function cleanup() {
            mounted = false
        }
    },[trigger , t])

    const settings = {
        dots: false,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
        arrows : false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const products1 = useRef();

    const next = () => {
        products1.current && products1.current.slickNext();
    }
    const previous = () => {
        products1.current &&  products1.current.slickPrev();
    }
    return (
        <div className="main">
           <div className="position-relative">
               {product &&
               <Slider
                   ref={slider => (products1.current = slider)}
                   {...settings}
                   className={'animated zoomIn'}
               >
                   {product && product.filter(e=>e.status === 1).map((e, i)=>(
                       <div key={i} className="box position-relative"
                       >
                           <>
                               <div className="container-fluid">
                                   <div className="row">
                                       <div className="section col-lg-8 col-md-12 col-sm-12 col-12 head mt-5 pt-5">
                                           <h1>{e.locale.name}</h1>
                                           <div className="row ">
                                               <div className="prices1 prices pr-lg-3 pr-md-4 pr-sm-4 pr-0 col-lg-5 col-md-12 col-sm-12 col-12">
                                                   <p className='my-0'>Kredit məbləği</p>
                                                   <div className="d-flex align-items-center">
                                                       <div className="d-flex">
                                                           <p>{Math.min.apply(Math, e.properties.map(function(o) { return o.min_amount; }))}</p>
                                                           <img src={Image.Manat} alt="" className="manat"/>
                                                       </div>
                                                       <div className="d-flex">
                                                           <p> - {Math.max.apply(Math, e.properties.map(function(o) { return o.max_amount; }))}</p>
                                                           <img src={Image.Manat} alt="" className="manat"/>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="prices2 prices px-lg-3 px-md-3 px-sm-3 px-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                                                   <p className='my-0'>Kredit müddəti</p>
                                                   <div className="d-flex align-items-center">
                                                       <div className="d-flex ">
                                                           <p>{Math.min.apply(Math, e.properties.map(function(o) { return o.max_loan_period; }))}</p>
                                                           <span>ildən</span>
                                                       </div>
                                                       <div className="d-flex">
                                                           <p> - {Math.max.apply(Math, e.properties.map(function(o) { return o.max_loan_period; }))}</p>
                                                           <span>ilədək</span>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="prices2 prices px-lg-3 px-md-3 px-sm-3 px-3 col-lg-4 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                                                   <p className='my-0'>Güzəşt müddəti</p>
                                                   <div className="d-flex align-items-center">
                                                       <div className="d-flex">
                                                           <p>{Math.min.apply(Math, e.properties.map(function(o) { return o.grace_period; }))}</p>
                                                           <span>ay</span>
                                                       </div>
                                                       <div className="d-flex">
                                                           <p>- {Math.max.apply(Math, e.properties.map(function(o) { return o.grace_period; }))}</p>
                                                           <span>ay</span>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           <p>PRIORITET İSTİQAMƏTLƏR</p>
                                           {e.use_cases[localStorage.getItem('locale')].map((u  , index)=>{
                                            return    <div className="advices w-75 d-flex align-items-center">
                                                        <img src={Image.Star} alt=""/>
                                                        <span>{u[index+1]}</span>
                                                      </div>
                                           })}

                                           <div className="d-flex mt-lg-0 mt-md-0 mt-sm-2 mt-2 align-items-center">
                                               {/*<div className="transition">*/}
                                               {/*    <Link to="/contact" className="d-flex line text-decoration-none align-items-center">*/}
                                               {/*        <p>MÜRACİƏT EDİN</p>*/}
                                               {/*        <svg xmlns="http://www.w3.org/2000/svg" width="17.066" height="12" viewBox="0 0 17.066 12"><path d="M11.492,4.854a.605.605,0,0,0-.86.851l4.361,4.361H.6a.6.6,0,0,0-.6.6.606.606,0,0,0,.6.611H14.993l-4.361,4.353a.617.617,0,0,0,0,.86.6.6,0,0,0,.86,0L16.886,11.1a.592.592,0,0,0,0-.851Z" transform="translate(0 -4.674)" fill="black"/></svg>*/}
                                               {/*    </Link>*/}
                                               {/*</div>*/}
                                               <div className="transition-text">
                                                   <Link  to={{
                                                       pathname: `/credit-content/${e.id}`,
                                                       state: { name: e.locale.name }
                                                   }}><p className={'pl-0'}>DAHA ƏTRAFLI</p></Link>
                                               </div>
                                           </div>
                                           <div className="home-transition">
                                               <div className="d-flex">
                                                   <a onClick={previous()}>
                                                       <svg xmlns="http://www.w3.org/2000/svg" width="9.203" height="30.394" viewBox="0 0 9.203 30.394"><path d="M0,26.1l1.249-1.215,2.537,2.465V0h1.9V27.309l2.6-2.434L9.2,25.8,5.4,30.133l-.358-.347-.625.608Z" fill="#272635" opacity="0.2"/></svg>
                                                   </a>
                                                   <a onClick={next()}>
                                                       <svg xmlns="http://www.w3.org/2000/svg" width="9.203" height="30.394" viewBox="0 0 9.203 30.394"><path d="M3.786,30.394V3.043L1.249,5.508,0,4.294,4.418,0l.625.607L5.4.259,9.2,4.6l-.921.921-2.6-2.433V30.394Z" fill="#272635" opacity="0.2"/></svg>
                                                   </a>
                                               </div>
                                           </div>

                                           <div className="row range-text range">
                                               <div className="col-lg-4 col-md-5 col-sm-10 col-9 mr-5">
                                                   <p>Kredit məbləği</p>
                                                   <div className="d-flex ">
                                                       <p>{Math.max.apply(Math, e.properties.map(function(o) { return o.max_amount; }))}</p>
                                                       <img src={Image.BlackManat} alt=""/>
                                                   </div>
                                                   <Form>
                                                       <Form.Group controlId="formBasicRange">
                                                           <Form.Control type="range" />
                                                       </Form.Group>
                                                   </Form>
                                               </div>
                                               <div className="col-lg-4 col-md-5 col-sm-10 col-9 mt-lg-0 mt-md-0 mt-sm-3 mt-3">
                                                   <p>Kredit müddəti</p>
                                                   <div className="d-flex align-items-center">
                                                       <p>{Math.max.apply(Math, e.properties.map(function(o) { return o.max_loan_period; }))}</p>
                                                       <span>ildən</span>
                                                   </div>
                                                   <Form>
                                                       <Form.Group controlId="formBasicRange">
                                                           <Form.Control type="range" />
                                                       </Form.Group>
                                                   </Form>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div className="percent">
                                   <p>{e.loan_percent_by_sif + e.loan_percent_by_bank}<sup>%</sup></p>
                               </div>
                           </>
                       </div>
                   ))}
               </Slider>
               }
            <div>
                <img src={Image.Man} alt="" className="man"/>
            </div>
            <div>
                <img src={Image.Map} alt="" className="bg-image"/>
            </div>
        </div>
        </div>
    );
}

const mapStateToProps = ({ product  , lang}) => {
    return { product , lang };
}

export default connect(mapStateToProps, { getProductList  })(Credit);

