import React from 'react';

function MissionCard(props) {
    return (
        <div className={`card blurCard ${props.index <= 1 && 'lighter'}`}>
            <div className="card-body">
                <div className="card-head d-flex align-items-center justify-content-between">
                    <h5 className="font-weight-bold">
                        {props.name}
                    </h5>
                    <img src={props.image} alt=""/>
                </div>
                <div className="card-content pt-2">
                    {props.content}
                </div>
            </div>
        </div>
    );
}


export default MissionCard;
