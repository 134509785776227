import React, {useEffect, useState} from 'react';
import Image from "../../../../assets/img/Image";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

function Documents(props){
    const {t} = useTranslation();
    const {locations , postList} = props
    const [documents , setDocuments] = useState([]);
    useEffect(()=>{
        if (postList.length > 0){
            let data =  postList.filter(f => f.is_active === 1)
            setDocuments(data)
        }
    } ,[postList])
    return (
        <div className="row animated fadeIn">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12 head">
                <div>
                    <h1>{locations}</h1>
                </div>
                <div className="documents-text">
                    {documents && documents?.map((l,index)=>(
                        <div key={index}  className="d-flex align-items-baseline">
                            <img src={Image.Star} alt=""/>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: l.locale?.name
                                }}
                            />
                        </div>
                    ))}
                </div>


                {
                    props?.product?.locale?.document_note ?
                    <div className="d-flex align-items-baseline document-note">
                        <p className={'note'}>{t('documents.documentsNote')}</p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html:props?.product?.locale?.document_note
                            }}
                        />
                    </div> :  undefined
                }
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="credit-about">
                    <p>{t('documents.documentsTitle')}</p>
                </div>
                <div>
                    <img src={Image.Document} alt="" className="document"/>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = ({  locations }) => {
    return {  locations };
};

export default connect(mapStateToProps)(Documents);

