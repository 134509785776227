import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import Slider from "react-slick";

function ClientHistory(props) {
    const clienthistory = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };
    const next = () => {
        clienthistory.current.slickNext();
    }
    const previous = () => {
        clienthistory.current.slickPrev();
    }
    return (
        <div className="main">
           <div className="position-relative">
            <div className="container-fluid">
                <div className="row margin mt-lg-2 mt-md-3 mt-sm-5 mt-5 pt-5 ">
                    <div className=" col-lg-6 col-md-7 col-sm-12 col-12">
                        <div className="d-flex page-head">
                            <p>Müştərilərimizin tarixi</p>
                        </div>
                        <div className="page-text mt-lg-0 mt-md-0 mt-sm-3 mt-3">
                            <p>Uğurumuz Müştərilərimizin Uğuruna aiddir, aşağıya nəzər yetirin</p>
                        </div>
                    </div>
                    <div className="row client-links d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start align-items-center p-0 col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="transition col-lg-4 col-md-6 col-sm-11 col-11 p-0">
                            {/*<Link to="/gallery" className="d-flex line align-items-center">*/}
                            {/*    <p>Daha ətraflı</p>*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="17.066" height="12" viewBox="0 0 17.066 12"><path d="M11.492,4.854a.605.605,0,0,0-.86.851l4.361,4.361H.6a.6.6,0,0,0-.6.6.606.606,0,0,0,.6.611H14.993l-4.361,4.353a.617.617,0,0,0,0,.86.6.6,0,0,0,.86,0L16.886,11.1a.592.592,0,0,0,0-.851Z" transform="translate(0 -4.674)" fill="black"/></svg>*/}
                            {/*</Link>*/}
                        </div>
                        <div className="service-link col-lg-5 col-md-6 col-sm-11 col-11  mt-lg-0 mt-md-2 mt-sm-3 mt-3 ">
                            <div className="arrows-client mt-0 p-0 mb-lg-0 mb-md-3 mb-sm-0 mb-0">
                                <Link onClick={()=>{previous()}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                </Link>
                                <Link onClick={() => {next()}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10 position-relative client-img p-0">
                    <div className="client-slider">
                        <div className="client-photo">
                            <Slider
                                className='mainslider'
                                ref={slider => (clienthistory.current = slider)}
                                {...settings}
                            >
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo8} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>President of the Republic of Azerbaijan Ilham Aliyev</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo1} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>President of the Republic of Azerbaijan Ilham Aliyev</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo7} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">İSTEHSAL</Link>
                                            <p>Medical masks manufacturing</p>
                                            <span>Minister of Economy Mikayil Jabbarov and director of Baku</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo2} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">İSTEHSAL</Link>
                                            <p>Medical masks manufacturing</p>
                                            <span>The enterprise creates more than 30 new jobs which will</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo3} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>The enterprise creates more than 30 new</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo4} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>At the initial stage, the enterprise will</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo8} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>President of the Republic of Azerbaijan Ilham Aliyev</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo1} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>President of the Republic of Azerbaijan Ilham Aliyev</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo7} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">İSTEHSAL</Link>
                                            <p>Medical masks manufacturing</p>
                                            <span>Minister of Economy Mikayil Jabbarov and director of Baku</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo2} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">İSTEHSAL</Link>
                                            <p>Medical masks manufacturing</p>
                                            <span>The enterprise creates more than 30 new jobs which will</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo3} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>The enterprise creates more than 30 new</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 pr-3">
                                    <div className="position-relative img">
                                        <img src={Image.Photo4} alt=""/>
                                        <div className="lay"></div>
                                        <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                            <Link to="/">AGRO BUSINESS</Link>
                                            <p>Aghstafa Agro- Industrialial...</p>
                                            <span>At the initial stage, the enterprise will</span>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img src={Image.Map} alt="" className="bg-image"/>
            </div>

        </div>
        </div>
    );
}

export default ClientHistory;
