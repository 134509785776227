import React, {useEffect} from 'react';
import Image from "../../../assets/img/Image";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getTerms , setAccept } from "../../../redux/actions";
import history from "../../../const/history";
import {useTranslation} from "react-i18next";

function VideoApply(props) {
    const {t , i18n} = useTranslation()
    const {getTerms, terms , setAccept , accepted} = props;
    useEffect(() => {
        if (Object.keys(terms).length === 0 || i18n.language !== props.lang ) {
            getTerms()
        }
    },[t]);

    const approve = async  () =>{
        await  setAccept(true)
        history.push('/apply-form')
    }


    return (
        <div className="main position-relative ">
            <div className="container-fluid">
                <div className="mt-5 pt-5">
                    {

                        <div>
                            <div className="page-head mt-lg-2 mt-md-2 mt-sm-5 mt-5 p-0">
                                <p>{terms?.locale && terms?.locale?.name}</p>
                            </div>
                            <div className="video-apply-text justify-content-center">
                                {terms?.locale &&
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: terms.locale?.description
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className="justify-content-center d-flex mt-1 pt-1 video-apply-transition">
                    {terms?.locale &&
                    <a  href={terms.working_date_file.path} target="_blank" className="d-flex">
                        {t('videoApply.workingTime')}
                    </a>
                    }
                </div>
                <div className="justify-content-center d-flex mt-4  pt-3 video-apply-transition">
                    {terms?.locale &&
                        <Link onClick={()=>{approve() }} className="d-flex">
                            {t('videoApply.acceptTerms')}
                            <img className="ml-3" src={Image.RightArrow} alt=""/>
                        </Link>
                    }
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({terms , accepted , lang }) => {
    return {terms , accepted , lang }
};


export default connect(mapStateToProps, {getTerms, setAccept })(VideoApply);
