import React, {useEffect, useState} from 'react';
import Fade from 'react-bootstrap/Fade'
import Header from "../../Layout/Header/Header";
import {connect} from "react-redux";
import Social from "../../Elements/Social/Social";
import {
    getCategories,
    getStatistics,
    toggleMenu,
    getProductList,
    getLegistation,
    getMission
} from './../../../redux/actions'
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import {useTranslation} from "react-i18next";
import api from "../../../const/api";
import Footer from "../../Layout/Footer/Footer";
function MenuList(props) {
    const {legistation , getLegistation} = props;
    const {getProductList , product} = props
    const {categories, getCategories, getStatistics, statistics , missions , getMission } = props;
    const [length, setLength] = useState(0)
    const {t} = useTranslation();
    const [curr, setCurr] = useState([])
    const [ent, setEnt] = useState(0)
    const [transParent, setTranspatent] = useState(false)
    const [proTotal, setProTotal] = useState(0)
    const [loanCost, setLoanCost] = useState(0)
   let  legistationLinks = [
        {name:t('legistation.constitution')},
        {name:t('legistation.laws')},
        {name:t('legistation.orders')},
        {name:t('legistation.decisions')},
        {name:t('legistation.internalRules')},
    ]
    const numbFunc = (key) => {
        let res1 = []
        if (key) {
            for (let i = 0, len = key?.toString().length; i < len; i += 1) {
                res1.push(<span>{+key?.toString().charAt(i)}</span>);
            }
        }
        return res1
    }

    const getCurrency = () => {
        api.get(`/currencies`).then((res) => {
            setCurr(res.data.content);
        });
    };


    useEffect(() => {
        let mounted =  true
        if(mounted){
            if (!statistics?.length) {
                getStatistics()
            }
            if (!legistation?.length) {
                getLegistation()
            }
            if (!missions?.length) {
                getMission()
            }
            if (!product?.length){
                getProductList()
            }
            statistics?.projects_total_cost &&  setEnt(statistics.projects_total_cost)
            statistics?.projects_total_cost && setProTotal(statistics.projects_total_cost)
            statistics?.state_soft_loan_cost &&   setLoanCost(statistics.state_soft_loan_cost)
            if (!categories.length) {
                getCategories()
                setLength(categories.filter((d) => {
                    return d.status === 1
                })?.length)
            }
        }
        return function cleanup() {
            mounted = false
        }
    }, [t , props.toggleReducer ]);



    const {toggleMenu} = props;
    return (
        <Fade in={props.toggleReducer}>
            <div className='menu-bar animated fadeIn'>
                {props.toggleReducer &&
                <div className="main">
                    <Header/>
                    <div className="position-relative">
                        <div className="container-fluid">
                            <div className="section">
                                <div className={`row  ${length >= 4 ? "pt-0 mt-0" : " margin-menu pt-3"}`}>
                                    <div className="col-lg-7 animated fadeInLeft col-md-6 col-sm-12 col-12 p-0 mt-lg-0 mt-md-3 mt-sm-2 mt-2 mx-0 menu">
                                        <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>
                                            <Link className={`${length >= 4 ? "length" : " "}`} to="/">{t('menu.homePage')}</Link>
                                        </div>
                                        <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>
                                            <Link className={`${length >= 4 ? "length" : " "}`} to="/about">{t('menu.about')}</Link>
                                            <section className="animated subs fadeInLeft ml-4 mt-2">
                                                {missions?.length > 0 ?
                                                    <div className="row align-items-center mt-3">
                                                        <div className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link to={'/about/0'}  className={'text-dark subLink  font-weight-bold'}>{t('menu.history')}</Link></div>
                                                        {
                                                            missions?.map( (d, index) => (
                                                                <div key={index} className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link to={`/about/${d.id}`}  className={'text-dark subLink  font-weight-bold'}>{d.locale.title.toLocaleUpperCase(localStorage.getItem('locale'))}</Link></div>
                                                            ) )
                                                        }
                                                        {/*<div className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link to={`/about/${missions.length + 2}`}  className={'text-dark subLink  font-weight-bold'}>FƏALİYYƏT İSTİQAMƏTLƏRİ</Link></div>*/}
                                                        <div className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link   to={`/about/4`} className={'text-dark  subLink font-weight-bold'}>{t('menu.fundStructure')}</Link></div>
                                                        <div className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link  to={`/about/5`} className={'text-dark  subLink font-weight-bold'}>{t('menu.directory')}</Link></div>
                                                        <div className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link to={`/about/6`} className={'text-dark  subLink font-weight-bold'}>{t('menu.reports')}</Link></div>
                                                        <div className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link  to={`/about/7`} className={'text-dark  subLink font-weight-bold'}>{t('menu.internationalPartners')}</Link></div>
                                                    </div>: null
                                                }
                                            </section>
                                        </div>
                                        {/*<div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>*/}
                                        {/*    <span>3</span>*/}
                                        {/*    <Link className={`${length >= 4 ? "length" : " "}`} to="/credit">KREDİT</Link>*/}
                                        {/*</div>*/}

                                           <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>
                                            <Link className={`${length >= 4 ? "length" : " "}`} to="/products">{t('menu.products')} </Link>
                                            <section className="animated subs fadeInLeft ml-4 mt-2">
                                                <div className="row align-items-center mt-3">
                                                    {product.length > 0 && product?.map((d, i)=>(
                                                        <div key={i} className="col-md-4 mt-2 col-sm-6 col-12 p-0">
                                                            <Link
                                                                to={{
                                                                pathname: `/credit-content/${d.id}`,
                                                                state: { name: d.locale.name }
                                                                }}
                                                                className={'text-dark subLink  font-weight-bold'}>
                                                                {d.locale.name.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </section>
                                        </div>
                                        <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>
                                            <Link className={`${length >= 4 ? "length" : ""}`} to="/credit-organization">{t('menu.creditOrganization')}</Link>
                                        </div>

                                        <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>
                                            <Link to={'owner-map'} className={`${length >= 4 ? "length" : ""}`} >{t('menu.roadMap')}</Link>
                                            <section className="animated subs  fadeInLeft ml-4 mt-2">
                                                <div className="row align-items-center   mt-3">
                                                    {legistation
                                                        .filter((i)=>{return  i.position === 5})
                                                        .map((l, i) => {
                                                       return <div className="col-md-4 mt-2 col-sm-6 col-12 p-0"><a  href={l.link} target={'_blank'} className={'text-dark px-2 subLink font-weight-bold'}>{l.locale.name.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}</a></div>
                                                    } )}
                                                </div>
                                            </section>
                                        </div>

                                        <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>
                                            <Link className={`${length >= 4 ? "length" : ""}`} >{t('menu.legistation')}</Link>
                                            <section className="animated subs  fadeInLeft ml-4 mt-2">
                                                <div className="row align-items-center  mt-3">
                                                    {legistationLinks.length > 0 &&
                                                        legistationLinks.map((l , i)=>(
                                                            <div key={i} className="col-md-4 mt-2 col-sm-6 col-12 p-0"><Link to={`/docs/${i}`} className={'text-dark  subLink font-weight-bold'}>{l.name.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}</Link></div>
                                                        ))
                                                    }
                                                </div>
                                            </section>
                                        </div>


                                        <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}}>
                                            <Link to={'/posts'} className={`${length >= 4 ? "length" : ""}`} >{t('menu.news')}</Link>
                                            <section className="animated subs fadeInLeft ml-4 mt-2">
                                                <div className="row align-items-center  mt-3">
                                                    {categories?.length > 0 ? categories?.filter((m) => {
                                                        return m.status === 1
                                                    }).map((c, i) => (
                                                    <div className="col-md-3 mt-2 col-sm-6 col-12 p-0"><Link className={'text-dark  subLink font-weight-bold'}  to={{pathname: `/posts`, state: { id: c.id ,  name: c.locale.name.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}}}>{c.locale.name.toLocaleUpperCase(localStorage.getItem('locale').toUpperCase())}</Link></div>
                                                    )) : null}
                                                </div>
                                            </section>
                                        </div>


                                        <div className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  }  onClick={() => {toggleMenu()}} >
                                            <Link className={`${length >= 4 ? "length" : ""}`} to="/gallery">{t('menu.gallery')}</Link>
                                        </div>

                                        <div  className={transParent && 'opacity'} onMouseEnter={ () => { setTranspatent(true) } }  onMouseLeave={ () => { setTranspatent(false) }  } onClick={() => {toggleMenu()}} >
                                            <Link className={`${length >= 4 ? "length" : ""}`} to="/contact">{t('menu.contact')}</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-5  animated fadeInRight col-md-6 col-sm-12 col-12  ">
                                        <div className="row">
                                            {statistics ?
                                            <div className="static ml-0 animated fadeIn w-100">
                                                <div className="w-100">
                                                    <p className={'ml-3 pr-0'}>{t('menu.beInvestor')}</p>
                                                </div>
                                                <div className=" col-12 numbers">
                                                    <div>
                                                        <div className="mr-2">
                                                            <p className={'pr-0'}>{t('menu.employer')}</p>
                                                            {numbFunc(ent && ent)}
                                                        </div>
                                                        <div className={'w-100'}>
                                                            <p className={'w-100 pr-0'}>{t('menu.projectTotalCost')}</p>
                                                            <div>{numbFunc(proTotal && proTotal)}</div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <p>{t('menu.givenCredit')}</p>
                                                            {numbFunc(loanCost && loanCost)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                            }

                                        </div>
                                        <div className="row ">
                                            <a href={'https://heydar-aliyev-foundation.org/az'} target={'_blank'} className="col-lg-4 offset-lg-2  mx-0  col-md-4  col-6  mt-2">
                                                <img  className={'w-100'} src={Image.HEfund} alt=""/>
                                            </a>
                                            <a href={'https://heydaraliyevcenter.az/#main'} target={'_blank'} className="col-lg-4 offset-lg-2  mx-0  col-md-4 col-6  mt-2">
                                                <img className={'w-100'}  src={Image.HEliyev} alt=""/>
                                            </a>
                                            <a href={'https://economy.gov.az/'} target={'_blank'} className="col-lg-4 offset-lg-2  mx-0 col-md-4  col-6  mt-2">
                                                <img className={'w-100'}  src={Image.Iqt} alt=""/>
                                            </a>
                                            <a href={'https://president.az/'} target={'_blank'} className="col-lg-4 offset-lg-2  mx-0 col-md-4  col-6  mt-2">
                                                <img className={'w-100'}  src={Image.IEliyev} alt=""/>
                                            </a>
                                            <a href={'https://mehriban-aliyeva.az/'} target={'_blank'} className="col-lg-4 offset-lg-2  mx-0  col-md-4 col-6  mt-2">
                                                <img className={'w-100'}  src={Image.MEliyeva} alt=""/>
                                            </a>
                                            <a href={'https://www.virtualkarabakh.az/'} target={'_blank'} className="col-lg-4 offset-lg-2  mx-0 col-md-4 col-6   mt-2">
                                                <img className={'w-100'}  src={Image.Qarabag} alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                        <div>
                            <img src={Image.Map} alt="" className="bg-image"/>
                        </div>
                    </div>
                </div>
                }
            </div>
        </Fade>
    );
}

const mapStateToProps = ({toggleReducer, statistics, categories ,missions ,product ,legistation}) => {
    return {toggleReducer, statistics, categories , product , missions , legistation}
}
export default connect(mapStateToProps, {toggleMenu, getStatistics, getCategories , getLegistation, getMission, getProductList})(MenuList);
