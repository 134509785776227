import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
import api from "../../../const/api";
// import moment from "moment";
import Lightbox from "react-image-lightbox";

function Full(props) {
    const {t} = useTranslation()
    const [selectedIndex , setSelectedIndex] = useState(undefined)
    const  handleCloseModal = (e) =>{
        setSelectedIndex(undefined)
        e && e.preventDefault();
    }
    const handleClickImage = (e , image , selectedIndex) =>{
        e && e.preventDefault();
        setSelectedIndex(selectedIndex)
    }


    const {id } = props.match.params
    const galleryzz = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };
    const next = () => {
        galleryzz.current.slickNext();
    }
    const previous = () => {
        galleryzz.current.slickPrev();
    }

    const [spin , setSpin] = useState(false);
    const [gallers, setGallery] = useState([]);
    const getGalleries = () => {
        setSpin(true)
        api.get(`galleries/${props.match.params.id}` ).then((res) => {
            res.data.content && setSpin(false)
            console.log(res.data.content.items)
            setGallery(
               res.data.content
            );
        });
    };

    useEffect(() => {
        getGalleries()
    }, [props.match.params.id , t]);

    return (
        <div className="main">
            <div className="position-relative">
                {!spin &&
                 <div className="animated fadeIn container-fluid">
                    <div className="row margin mt-lg-2 mt-md-3 mt-sm-5 mt-5 pt-5">
                        <div className=" col-lg-8 col-md-8 col-sm-12 col-12">
                            <div className="d-flex align-items-center">
                                <Link to="/gallery" className="about-back">
                                    <img src={Image.Back} alt=""/>
                                </Link>
                                <div className="d-flex page-head">
                                    <p className={gallers.locale?.name?.length > 34 && 'h5'}>{gallers.locale?.name}</p>
                                </div>
                            </div>
                            <div className="page-text mt-lg-0 mt-md-0 mt-sm-3 mt-3">
                                <div>{gallers.locale?.description}</div>
                                {!gallers.items?.length &&
                                   <div className={'text-danger h5'}>{t('gallery.noPhotoError')}</div>
                                }
                            </div>
                        </div>

                        <div className="row client-links d-flex justify-content-end align-items-center p-0 col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="transition col-lg-4 col-md-6 col-sm-11 col-11 p-0">
                                {/*<Link to="/gallery" className="d-flex line align-items-center">*/}
                                {/*    <p>Daha ətraflı</p>*/}
                                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="17.066" height="12" viewBox="0 0 17.066 12"><path d="M11.492,4.854a.605.605,0,0,0-.86.851l4.361,4.361H.6a.6.6,0,0,0-.6.6.606.606,0,0,0,.6.611H14.993l-4.361,4.353a.617.617,0,0,0,0,.86.6.6,0,0,0,.86,0L16.886,11.1a.592.592,0,0,0,0-.851Z" transform="translate(0 -4.674)" fill="black"/></svg>*/}
                                {/*</Link>*/}
                            </div>
                            <div className="service-link col-lg-5 col-md-6 col-sm-11 col-11 mt-0">
                                {gallers.items?.length > 4 &&
                                <div className="arrows-client p-0 m-0">
                                    <Link onClick={()=>{previous()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                    </Link>
                                    <Link onClick={() => {next()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                    </Link>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-11 col-sm-10 col-10 position-relative client-img p-0">
                        <div className="client-slider">
                            <div className="client-photo">
                                <>
                                    {gallers.items?.length < 4 ?
                                        <div className="row">
                                            {gallers.items?.map((l,i)=>(
                                                <div key={i} className="mb-4 col-md-6 col-12">
                                                    <div className="position-relative img">
                                                        <img src={l.media.path}  onClick={ e => handleClickImage(e, l.media.path , i)} alt=""/>
                                                        <div onClick={ e => handleClickImage(e, l.media.path , i)} className="lay"></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>:
                                        <Slider
                                            className='mainslider'
                                            ref={slider => (galleryzz.current = slider)}
                                            {...settings}
                                        >
                                            {gallers.items?.map((l,i)=>(
                                                <div key={i} className="mb-3 pr-3">
                                                    <div  onClick={ e => handleClickImage(e, l.media.path , i)} className="position-relative img">
                                                        <img src={l.media.path}  alt=""/>
                                                        <div  className="lay"/>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                    {selectedIndex !== undefined &&
                        <Lightbox
                            mainSrc={gallers.items[selectedIndex].media.path}
                            nextSrc={gallers.items[(selectedIndex + 1) % gallers.items.length].media.path}
                            prevSrc={gallers.items[(selectedIndex + gallers.items.length - 1) % gallers.items.length].media.path}
                            onMovePrevRequest={() =>
                                setSelectedIndex((selectedIndex + gallers.items.length - 1) % gallers.items.length )
                            }
                            onMoveNextRequest={() =>
                                setSelectedIndex((selectedIndex + gallers.items.length + 1) % gallers.items.length )
                            }
                            onCloseRequest={handleCloseModal}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default Full;
