import types from '../types'


export const toggleReducer = (data =false , action) =>{
    switch (action.type){
        case types.TOGGLE:
            return !data;
        default :
            return data;
    }
}

export const hoverReducer = (hover = false , action) =>{
    switch (action.type){
        case types.HOVER:
            return true;
        case types.OUT:
            return false;
        default :
            return hover ;
    }
}


export const acceptReducer = (accept = false , action) =>{
    switch (action.type){
        case types.ACCEPT:
            return true;
        case types.NOTACCEPT:
            return false;
        default :
            return accept ;
    }
}


export const LocationReducer = (name ='' , action) =>{
    switch (action.type){
        case types.LOCATION_SUCCESS:
            return action.payload;
        default :
            return name;
    }
}



export function productReducer(product= [], action) {
    switch (action.type) {
        case types.GET_PRODUCT:
            return action.payload;
        case types.GET_PRODUCT_ERROR:
            return product;
        default:
            return product;
    }
}


export function postReducer(posts=[], action) {
    switch (action.type) {
        case types.GET_POSTS:
            return action.payload;
        case types.GET_POSTS_ERROR:
            return posts;
        default:
            return posts;
    }
}

export function partnerReducer(partners=[], action) {
    switch (action.type) {
        case types.GET_PARTNERS:
            return action.payload;
        case types.GET_PARTNERS_ERROR:
            return partners;
        default:
            return partners;
    }
}




export function reportItemsReducer(reportItems={}, action) {
    switch (action.type) {
        case types.GET_REPORT_ITEMS:
            return action.payload;
        case types.GET_REPORT_ITEMS_ERROR:
            return reportItems;
        default:
            return reportItems;
    }
}

export function partnerPage(partners=[], action) {
    switch (action.type) {
        case types.GET_PARTNERPAGE:
            return action.payload;
        case types.GET_PARTNERPAGE_ERROR:
            return partners;
        default:
            return partners;
    }
}

export function aboutReducer(about=[], action) {
    switch (action.type) {
        case types.GET_ABOUT:
            return action.payload;
        case types.GET_ABOUT_ERROR:
            return about;
        default:
            return about;
    }
}

export function bankReducer(banks=[], action) {
    switch (action.type) {
        case types.GET_BANKS:
            return action.payload;
        case types.GET_BANKS_ERROR:
            return banks;
        default:
            return banks;
    }
}

export function projectReducer(projects=[], action) {
    switch (action.type) {
        case types.GET_PROJECTS:
            return action.payload;
        case types.GET_PROJECTS_ERROR:
            return projects;
        default:
            return projects;
    }
}



export function bankBranchReducer(banksBranches=[], action) {
    switch (action.type) {
        case types.GET_BANKS_BRANCHES:
            return action.payload;
        case types.GET_BANKS_BRANCHES_ERROR:
            return banksBranches;
        default:
            return banksBranches;
    }
}

export function customersReducer(projects=[], action) {
    switch (action.type) {
        case types.GET_CUSTOMERS:
            return action.payload;
        case types.GET_CUSTOMERS_ERROR:
            return projects;
        default:
            return projects;
    }
}

export function missionsReducer(missions=[], action) {
    switch (action.type) {
        case types.GET_MISSION:
            return action.payload;
        case types.GET_MISSION_ERROR:
            return missions;
        default:
            return missions;
    }
}



export function statisticsReducer(stat=[], action) {
    switch (action.type) {
        case types.GET_STATISTICS:
            return action.payload;
        case types.GET_STATISTICS_ERROR:
            return stat;
        default:
            return stat;
    }
}

export function galleryReducer(gallery=[], action) {
    switch (action.type) {
        case types.GET_GALLERY:
            return action.payload;
        case types.GET_GALLERY_ERROR:
            return gallery;
        default:
            return gallery;
    }
}

export function termReducer(terms={}, action) {
    switch (action.type) {
        case types.GET_TERMS:
            return action.payload;
        case types.GET_TERMS_ERROR:
            return terms;
        default:
            return terms;
    }
}

export function directoryReducer(dir=[], action) {
    switch (action.type) {
        case types.GET_DIRECTORIES:
            return action.payload;
        case types.GET_DIRECTORIES_ERROR:
            return dir;
        default:
            return dir;
    }
}




export function categoryReducer(cat=[], action) {
    switch (action.type) {
        case types.GET_CATEGORIES:
            return action.payload;
        case types.GET_CATEGORIES_ERROR:
            return cat;
        default:
            return cat;
    }
}

export function contactInfo(info= [], action) {
    switch (action.type) {
        case types.GET_CONTACT:
            return action.payload;
        case types.GET_CONTACT_ERROR:
            return info;
        default:
            return info;
    }
}



export function legistationReducer(legistation= [], action) {
    switch (action.type) {
        case types.GET_LEGISTATION:
            return action.payload;
        case types.GET_LEGISTATION_ERROR:
            return legistation;
        default:
            return legistation;
    }
}

export function roadMapReducer(roadmap= [], action) {
    switch (action.type) {
        case types.GET_LEGISTATION:
            return action.payload;
        case types.GET_LEGISTATION_ERROR:
            return roadmap;
        default:
            return roadmap;
    }
}

export function instructionsReducer(instructions= [], action) {
    switch (action.type) {
        case types.GET_INSTRUCTIONS:
            return action.payload;
        case types.GET_INSTRUCTIONS_ERROR:
            return instructions;
        default:
            return instructions;
    }
}


export function faqsReducer(faqs= [], action) {
    switch (action.type) {
        case types.GET_FAQS:
            return action.payload.faqs;
        case types.GET_FAQS_ERROR:
            return faqs;
        default:
            return faqs;
    }
}

export function faqDataReducer(faqs= {}, action) {
    switch (action.type) {
        case types.GET_FAQS:
            return action.payload.locale;
        case types.GET_FAQS_ERROR:
            return faqs;
        default:
            return faqs;
    }
}

export const languageReducer = (language = [] , action) => {
    switch (action.type) {
        case types.GET_LANGS:
            return action.payload;
        case types.GET_LANGS_ERROR:
            return language;
        default:
            return language;
    }
}


export function langReducer(
    lang = localStorage.getItem("locale") || "az",
    action
) {
    switch (action.type) {
        case types.SET_LANG:
            return action.payload;
        case types.GET_LANG:
            return lang;
        default:
            return lang;
    }
}
