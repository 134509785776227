import React, {useEffect, useState, useRef} from 'react';
import {Link} from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup'
import { Button, FormControl } from 'react-bootstrap';
import {connect} from "react-redux";
import Image from "../../../../assets/img/Image";
import {getPartners , getPartnerPage} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";

function Partners(props) {
    const {getPartners  , partners } = props
    const [value, setValue] = useState('')
    const [text, setText] = useState('')
    const {t} = useTranslation();

    const trigger = 1



    return (
        <div className="main">
            <div className="position-relative container-fluid">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-head d-flex justify-content-between p-0">
                            <div className={'d-flex align-items-center'}>
                                <p>{partners.title}</p>
                            </div>
                            {/*<div className="faq-search">*/}
                            {/*    <InputGroup className="mb-3">*/}
                            {/*        <FormControl*/}
                            {/*            value={value}*/}
                            {/*            onChange={e => setValue(e.target.value)}*/}
                            {/*            placeholder="Axtarış"*/}
                            {/*        />*/}
                            {/*        <InputGroup.Append>*/}
                            {/*            <Button onClick={()=>{setText(value)}} variant="outline-secondary">*/}
                            {/*                <img src={Image.Search} alt=""/>*/}
                            {/*            </Button>*/}
                            {/*        </InputGroup.Append>*/}
                            {/*    </InputGroup>*/}
                            {/*</div>*/}
                        </div>
                        <div className="faq-text">
                            <p>
                                {/*{faqsData.description}*/}
                            </p>
                        </div>
                        <div className="faqs-slider">
                            <div className="row  video-apply-text px-lg-5 justify-content-center">
                                {
                                    partners.partners && partners.partners
                                        .filter(item => {
                                            if (!value) return true
                                            if (item.locale.question.includes(text) || item.locale.answer.includes(text)) {
                                                return true
                                            }
                                        })
                                        .map((p , index) =>(
                                            <div className="col-md-6 mb-4">
                                                <div className="faq-card partners w-100">
                                                    <div>
                                                        <img src={p.logo.path} alt=""/>
                                                        <span>
                                                           <p className={'mb-0'}> <strong>{p.locale.title}</strong></p>
                                                            <p>
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: p.locale.description
                                                                        }}
                                                                    />
                                                            </p>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                }
                            </div>

                        </div>

                    </div>
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ partners }) => {
    return { partners };
}


export default connect(mapStateToProps, {getPartners })(Partners);
