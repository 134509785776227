import React, {useRef, useEffect,useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Image";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getGallery} from "../../../redux/actions";
import {Helmet} from "react-helmet";

function Gallery(props) {
    const {galleries , getGallery} = props
    let [trigger, setTrigger] = useState(0);
    const { t , i18n} = useTranslation();
    useEffect(() => {
      if (!galleries.length || i18n.language !== props.lang){
          getGallery()
      }
    }, [trigger , t]);
    const gallery = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed: 1000,
                    autoplaySpeed: 4000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed: 1000,
                    autoplaySpeed: 4000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 2,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    dots: false,
                    infinite: true,
                    cssEase: 'linear',
                    autoplay: false,
                    speed: 1000,
                    autoplaySpeed: 4000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    rows: 1,
                }
            }
        ]
    };
    const next = () => {
        gallery.current.slickNext();
    }
    const previous = () => {
        gallery.current.slickPrev();
    }
    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')}  - { t('menu.gallery').toLocaleUpperCase(localStorage.getItem('locale').toUpperCase()) }</title>
            </Helmet>
            <div className="position-relative">
                {
                    galleries &&
                    <div className="animated fadeIn container-fluid">
                        <div className="row margin mt-lg-2 mt-md-3 mt-sm-5 mt-5 pt-5">
                            <div className=" col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="d-flex page-head">
                                    <p>{t('gallery.gallery')}</p>
                                </div>
                                {/*<div className="page-text mt-lg-0 mt-md-0 mt-sm-3 mt-3">*/}
                                {/*    <p className={'mb-0 ml-1'}>{t('gallery.galleryText')}</p>*/}
                                {/*</div>*/}
                            </div>
                            <div className="row client-links d-flex justify-content-end align-items-center p-0 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="transition col-lg-4 col-md-6 col-sm-11 col-11 p-0">
                                    {/*<Link to="/gallery/full/" className="d-flex line align-items-center">*/}
                                    {/*    <p>Daha ətraflı</p>*/}
                                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="17.066" height="12" viewBox="0 0 17.066 12"><path d="M11.492,4.854a.605.605,0,0,0-.86.851l4.361,4.361H.6a.6.6,0,0,0-.6.6.606.606,0,0,0,.6.611H14.993l-4.361,4.353a.617.617,0,0,0,0,.86.6.6,0,0,0,.86,0L16.886,11.1a.592.592,0,0,0,0-.851Z" transform="translate(0 -4.674)" fill="black"/></svg>*/}
                                    {/*</Link>*/}
                                </div>
                                <div className="service-link col-lg-5 col-md-6 col-sm-11 col-11 mt-0">
                                    {galleries.length > 6 &&
                                    <div className="arrows-client p-0 m-0">
                                        <Link onClick={()=>{previous()}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                        </Link>
                                        <Link onClick={() => {next()}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                        </Link>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-11 col-sm-10 col-10 position-relative client-img p-0">
                            <div className="client-slider">
                                <div className="client-photo">
                                    {galleries.length < 7 ?
                                        <div className="row">
                                            {galleries.map((l,i)=>{
                                                return (
                                                    <div className="col-lg-4 col-md-6 col-12 ">
                                                        <Link  to={{
                                                            pathname: `/gallery/full/${l.id}`,
                                                            state: { name: l.locale.name }
                                                        }} className="mb-3 pr-3">
                                                            <div className="position-relative img">
                                                                <img src={l.thumbnail.path} alt=""/>
                                                                <div className="lay"></div>
                                                                <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                                                    <p>{l?.locale.name}</p>
                                                                    <span>{l.locale.description}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            })}
                                        </div>:
                                        <Slider
                                            className='mainslider'
                                            ref={slider => (gallery.current = slider)}
                                            {...settings}
                                        >
                                            {galleries.map((l,i)=>{
                                                return (
                                                    <Link  to={{
                                                        pathname: `/gallery/full/${l.id}`,
                                                        state: { name: l.locale.name }
                                                    }} className="mb-3 pr-3">
                                                        <div className="position-relative img">
                                                            <img src={l?.thumbnail.path} alt=""/>
                                                            <div className="lay"></div>
                                                            <div className="client-photo-text col-lg-10 col-md-12 col-sm-10 col-10">
                                                                <p>{l?.locale.name}</p>
                                                                <span>{l.locale.description}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })}
                                        </Slider>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = ({ galleries  , lang }) => {
    return {galleries , lang};
};
export default connect(mapStateToProps, { getGallery  })(Gallery);


