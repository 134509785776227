import React, {useEffect , useState} from 'react';
import Image from "../../../../assets/img/Image";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

function CreditClassification(props) {
    const {t} = useTranslation();
    const {postList ,id, locations} = props
    return (
        <>
            {postList?.properties &&
            <div className="row animated fadeIn" >
                <div className="col-lg-6 col-md-10 col-sm-11 col-11 head">
                    <div>
                        <h1 className="ml-sm-3 ml-0">{locations}</h1>
                    </div>

                    {postList.properties?.map((l, i)=>(
                        <React.Fragment key={i}>
                            {l.locale.name &&
                            <div className="text-uppercase credit-sort mb-4 ml-3">
                                <p>{l.locale.name}</p>
                            </div>
                            }
                            <div className="d-flex ml-3 row">
                                <div className="prices1 prices col-lg-5 col-md-6 col-sm-12 col-12 p-0">
                                    <p className='my-0'>{t('creditClassification.creditAmount')}</p>
                                    <div className="d-flex ">
                                        {l.min &&
                                            <div className="d-flex mr-2">
                                                <p className={'amount'}>{l.min}</p>
                                                <img src={Image.Manat} alt="" className="manat"/>
                                            </div>
                                        }
                                        {
                                            l.max &&
                                            <div className="d-flex">
                                                <p className={'amount'}> {(l.min && l.max) &&  '-'} {l.max}</p>
                                                <img src={Image.Manat} alt="" className="manat"/>
                                            </div>
                                        }
                                    </div>
                                    <div
                                        className={'credit-max-size'}
                                        dangerouslySetInnerHTML={{
                                            __html: l.locale?.loan_period_info
                                        }}
                                    />

                                    {l.locale?.ratio_of_loan &&
                                        <p className='my-0 mt-3'>{t('creditClassification.loanRatio')}</p>
                                    }
                                    <div
                                        className={'credit-max-size'}
                                        dangerouslySetInnerHTML={{
                                            __html: l.locale?.ratio_of_loan
                                        }}
                                    />

                                </div>
                                {(l.year || l.locale.warranty_loan) &&
                                <div className="prices2-credit prices col-lg-5 col-md-6 col-sm-12 col-6 mt-lg-0 mt-md-0 mt-sm-0 p-0">
                                    <p className='my-0'>{t('creditClassification.creditDuration')}</p>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex mr-2 align-items-center">
                                            {l.year !== 0 &&
                                            <p>{l.year}</p>
                                            }
                                            <span
                                                className={'ml-1'}
                                                dangerouslySetInnerHTML={{
                                                    __html: l.locale?.warranty_loan
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="col-12 pl-0">
                                    <div className="d-flex align-items-baseline document-note note-margin">
                                        <p className={'note'}>{t('documents.documentsNote')}</p>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: l.locale?.text
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}

                    {
                        postList.locale.description &&
                        <div className="d-flex align-items-baseline document-note">
                            <p className={'note'}>{t('documents.documentsNote')}</p>
                            <span>{postList.locale.description}</span>
                        </div>
                    }

                    {/*<div className="credit-percent d-flex pl-3">*/}
                    {/*    <p>kreditlər:</p>*/}
                    {/*    <p>{postList.loan_percent_by_bank + postList.loan_percent_by_sif} %-dək ({postList.loan_percent_by_sif}% SİF + {postList.loan_percent_by_bank}% MKT)</p>*/}
                    {/*</div>*/}
                </div>
                <div>
                    <img src={Image.Man} alt="" className="credit-man"/>
                </div>
                <div className="percent-credit">
                        <div
                            className={props.product.locale?.priority_note.length > 10 ? "percent-size" : ''}
                            dangerouslySetInnerHTML={{
                                __html: props.product.locale?.priority_note
                            }}
                        />
                </div>
            </div>
            }
        </>
    );
}


const mapStateToProps = ({ locations }) => {
    return {  locations };
}

export default connect(mapStateToProps )(CreditClassification);
