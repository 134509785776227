import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Image from "../../../assets/img/Image";
import DownloadLink from "./DownloadLink";
import sif from "../../../const/api";
import {useTranslation} from "react-i18next";
import types from "../../../redux/types";

function RoadMap(props) {
    const {t} = useTranslation();
    const faq = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 4000,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        rows: 2,
    };

    const next = () => {
        faq.current.slickNext();
    }
    const previous = () => {
        faq.current.slickPrev();
    }

    const [reportItem , setReportItem] = useState([])
    const getReportItems = (id) => {
        sif
            .get(  `investor/roadmap/properties/${id}`)
            .then((res) => {
                setReportItem(res.data.content);
            })
            .catch((err) => {
                console.log(err)
                });
        };



    const [value, setValue] = useState('')
    const [text, setText] = useState('')
    const [reports , setReports] = useState([])
    const [show , setShow] = useState(false)
    const [name , setName] = useState(false)
    const [description , setDescription] = useState('')
    useEffect(() => {
        getData()
    }, [t])

    const getData = () =>{
        sif
            .get("investor/roadmap")
            .then((res) => {
                setReports(res.data.content)
                if (res.data.content.length > 0){
                    getReportItems(res.data.content[0]?.id)
                }
            })
    }

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    return (
        <div className="main">
            <div className={`position-relative ${!props.intab && 'pt-5 mt-5'} container-fluid`}>
                <div className="row pt-4 mt-lg-0 mt-md-0 mt-sm-5 mt-5">
                    <div className="col-12">
                        <div className="page-head mt-0 d-flex justify-content-between p-0">
                            <div className={'d-flex align-items-center'}>
                                {
                                    show &&
                                    <div onClick={()  => { setShow(false) } } className={'mr-2'}>
                                        <img src={Image.Back} alt=""/>
                                    </div>
                                }
                                <p>{!show ? t('menu.roadMap') : name }</p>
                            </div>
                            {/*<div className="faq-search">*/}
                            {/*    <InputGroup className="mb-3">*/}
                            {/*        <FormControl*/}
                            {/*            value={value}*/}
                            {/*            onChange={e => setValue(e.target.value)}*/}
                            {/*            placeholder="Axtarış"*/}
                            {/*        />*/}
                            {/*        <InputGroup.Append>*/}
                            {/*            <Button onClick={()=>{setText(value)}} variant="outline-secondary">*/}
                            {/*                <img src={Image.Search} alt=""/>*/}
                            {/*            </Button>*/}
                            {/*        </InputGroup.Append>*/}
                            {/*    </InputGroup>*/}
                            {/*</div>*/}
                        </div>
                        <div className="my-1 w-100">
                            {show &&
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: description
                                    }}
                                />
                            }
                        </div>
                        <div className="faqs-slider">
                            {
                                show ?
                                    <div className="row animated fadeIn video-apply-text px-0  justify-content-center">
                                        {
                                            reportItem.filter(item => item?.is_active === 1).map((report, index) => (
                                                    <div  className={'col-md-6 mb-2 animated fadeIn h-100'}>
                                                        <DownloadLink src={report?.file?.path}>
                                                            <div className="faq-card w-100">
                                                                <div className="d-flex align-items-center">
                                                                    <p className={'numb mb-0 mr-2'}>{pad(index + 1)}</p>
                                                                    <strong>{report.locale.name}</strong>
                                                                </div>
                                                            </div>
                                                        </DownloadLink>
                                                    </div>

                                                ))
                                        }
                                    </div> :
                                    <div className="row  animated fadeIn video-apply-text px-0  justify-content-center">
                                        {
                                            reports.filter(item => item?.is_active === 1)
                                                .map((report, index) => (
                                                    <div onClick={() =>{ getReportItems(report.id) ; setName(report.locale.name) ; setDescription(report.locale.description) ;  setTimeout( () =>{setShow(true) }  , 200 ) }} className={'col-md-6 mb-3 animated fadeIn h-100'}>
                                                        <div className="faq-card w-100">
                                                            <div className="d-flex align-items-center">
                                                                <p className={'numb mb-0 mr-2'}>{pad(index + 1)}</p>
                                                                <strong>{report?.locale?.name}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>
            </div>
        </div>
    );
}



export default RoadMap;
