import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../../assets/img/Image";
import ModalVideo from "react-modal-video";
import {connect} from "react-redux";
import {getAbout , getStatistics  , setHover} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";

function AboutFund(props) {
    const {getAbout , about , getStatistics , setHover, statistics} = props
    const [isOpen, setOpen] = useState(false)
    const [videoID , setVideoId] = useState('')
    const {t , i18n} = useTranslation();
    const [ent , setEnt] = useState(0)
    const [proTotal , setProTotal] = useState(0)
    const [loanCost , setLoanCost] = useState(0)

    const numbFunc = (key) =>{
        var res1 = []
       if (key){
           for (var i = 0, len = key?.toString().length; i < len; i += 1) {
               res1.push(<span>{+key?.toString().charAt(i)}</span>);
           }
       }
        return res1
    }

    useEffect(()=>{
        let mounted = true
        if (mounted){
            if (!statistics?.length|| i18n.language !== props?.lang ){
                getStatistics()
            }
            if((about && !Object.keys(about)?.length) || i18n.language !== props?.lang){
                getAbout()
            }
            function getId(url) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = url && url?.match(regExp);
                return (match && match[2].length === 11)
                    ? match[2]
                    : null;
            }
            const videoId = getId(about?.link);
            setVideoId(videoId)
        }
        return function cleanup() {
            mounted = false
        }
    } ,[t])


    return (
        <div className="row mt-lg-5 mt-0 p-0">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-sm-2 p-2">
                <div className="d-md-flex d-block animated fadeIn justify-content-between align-items-center">
                    <div className="page-head">
                        <p className="ml-md-3 ml-0">{t('aboutFund.title')}</p>
                    </div>
                    <div className="d-md-flex d-block align-items-center mb-lg-0 mb-md-0 mb-sm-3 mb-1 mt-1 about-link ml-2">
                        {/*<Link to="/about-content" className="m-0">DAHA ƏTRAFLI</Link>*/}
                        <Link onClick={() => setOpen(true)}>{t('aboutFund.video')}</Link>
                        <Link onClick={() => setOpen(true)}>
                            <img src={Image.Playicon} alt=""/>
                        </Link>
                    </div>
                </div>
                <div onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}}  className="page-text">
                    {about?.locale &&
                    <p
                        className={'animated main fadeIn  '}
                        dangerouslySetInnerHTML={{
                            __html: about.locale.about
                        }}
                    />

                    }
                </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-12 p-sm-0 pt-3">
                <div  onClick={() => setOpen(true)} className="animated zoomIn mw-100
                         videos">
                    <Link>
                        <img src={about?.picture?.path} alt=""/>
                    </Link>
                    <Link>
                        <img src={Image.Play} alt="" className="play"/>
                    </Link>
                </div>

                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoID} onClose={() => setOpen(false)} />

            </div>
        </div>
    );
}

const mapStateToProps = ({ about , statistics  , lang}) => {
    return {about,statistics , lang};
};

export default connect(mapStateToProps, { setHover , getAbout , getStatistics })(AboutFund);
