import React, {useEffect, useState} from 'react';
import Header from "./Layout/Header/Header";
import Routes from "./Layout/Routes/Routes";
import {connect} from "react-redux";
import Image from "../assets/img/Image";
import {changeLanguage} from "../redux/actions";
import sif from "../const/api";
import { I18nextProvider } from 'react-i18next';
import {az} from '../lang/az'
import i18n from "../i18n";
function App(props) {

    let [trigger , setTrigger]  = useState(0);
    const [show , setShow] = useState(false)
    useEffect(()=>{
        if (!localStorage.getItem('locale')){
            localStorage.setItem("locale", "az");
        }
        else {
            props.changeLanguage(localStorage.getItem('locale'))
        }
    })

    useEffect(()=>{
        sessionStorage.getItem('local') && setShow(true)
    },[trigger])

    useEffect(async  ()=>{
      setShow(false)
        console.log(!sessionStorage.getItem('local'))
        if (!sessionStorage.getItem('local')){
            let data = {}
            await  sif
                .get("languages/localization")
                .then((res) => {
                    res.data.content.map((d, l)=>{
                             data[d.local] = {translation: {}}
                             d.data ?  data[d.local]['translation'] = d.data :  data[d.local]['translation'] = az
                            sessionStorage.setItem('local' , JSON.stringify(data))
                            window.location.reload()
                            console.log(data)
                    })
                })
                .catch((err) => {
                    console.log(err)
                });
        }
        else{
            setShow(true)
        }
    },[])

    return (
        <div className='App'>
                <div>
                   {show ?
                    <>
                        <I18nextProvider i18n={show && i18n}>
                           <Header/>
                           <Routes/>
                        </I18nextProvider>
                    </>           :
                    <div style={{height:'100vh'}} className="d-flex justify-content-center align-items-center w-100 ">
                          <div>
                                <img src={Image.Map} alt=""className="bg-image"/>
                          </div>
                    </div>
                   }
                </div>

        </div>
    );
}



export default connect(({lang}) => lang , { changeLanguage })(App);

