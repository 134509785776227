import React from 'react';
import Social from "../../Elements/Social/Social";

function Footer(props) {
    return (
        <div className="footer d-flex justify-content-between">
            <div>
                <span>© 2020 EDF - All Rights Reserved.</span>
            </div>
            <div className="footer-text d-flex">
                <p>Developed by</p>
                <p>Virtual Azerbaijan</p>
            </div>
            <div>
                <Social/>
            </div>
        </div>
    );
}

export default Footer;
