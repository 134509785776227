import React, {useEffect, useRef} from 'react';
import Image from "../../../assets/img/Image";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import Map from '../../Elements/Map/Map'
import {connect} from "react-redux";
import {getLocation, getBanks, getCustomers} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function Credit(props) {
    const {t , i18n} = useTranslation();
    const organization = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        arrows : false,
        slidesToShow: 5,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    dots: false,
                    infinite: true,
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows : false,
                    horizontal:true,
                    horizontalSwipping: true,
                    rows: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            }
        ]
    };
    const next = () => {
        organization.current.slickNext();
    }
    const previous = () => {
        organization.current.slickPrev();
    }

    const { getBanks, banks } = props
    const {getCustomers, customers} = props
    const trigger = 1
    useEffect(()=>{
        if (props.type === 'banks'){
            if (!banks.length  || i18n.language !== props.lang ){
                getBanks()
            }
        }
        else {
            if(!customers.length || i18n.language !== props.lang ) {
                getCustomers()
            }
        }
    },[trigger])

    return (
        <div className="main">
            <Helmet>
                <title>{t('site.EDF')}  - {props.type === 'banks' ? t('creditOrganization.creditOrganizationTitle').toUpperCase() : t('home.customers').toUpperCase() }</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row pr-lg-5 mr-lg-5 pt-5 mt-lg-0 mt-md-5 mt-sm-5 mt-5 margin">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                           <div className="organization-head">
                               {props.type === 'banks' ? <p>{t('creditOrganization.creditOrganizationTitle')}</p> : <p>{t('home.customers')}</p> }
                           </div>
                            <div className={'responsive-map animated zoomIn'}>
                                <Map type={props.type} />
                            </div>
                            <div className="d-flex organization-link align-items-center">
                                <p>{t('creditOrganization.creditOrganizationAnswer')}</p>
                                <div className="transition p-0">
                                    <Link to="/about" className="d-flex line align-items-center">
                                        <p>{t('creditOrganization.creditOrganizationInformation')}</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.066" height="12" viewBox="0 0 17.066 12"><path d="M11.492,4.854a.605.605,0,0,0-.86.851l4.361,4.361H.6a.6.6,0,0,0-.6.6.606.606,0,0,0,.6.611H14.993l-4.361,4.353a.617.617,0,0,0,0,.86.6.6,0,0,0,.86,0L16.886,11.1a.592.592,0,0,0,0-.851Z" transform="translate(0 -4.674)" fill="black"/></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {
                            props.type === 'banks' &&
                            <div className="col-lg-3 offset-lg-1 col-md-10 col-sm-12 col-12 mt-lg-0">
                                <div className="d-flex align-items-center organization-transition justify-content-md-end mb-n4 mt-3 justify-content-start">
                                    {banks.length > 4 &&
                                        <>
                                            <Link onClick={()=>{previous()}}>
                                                <svg style={{transform: 'rotate(-90deg)'}} xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                            </Link>
                                            <Link  onClick={()=>{next()}}>
                                                <svg style={{transform: 'rotate(-90deg)'}} xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                            </Link>
                                        </>
                                    }
                                </div>
                                <div className="">
                                    <div className="organizaion animated fadeIn">
                                        {
                                            banks.length > 4 ?
                                                <Slider
                                                    className='mainslider'
                                                    ref={slider => (organization.current = slider)}
                                                    {...settings}
                                                >
                                                    {banks.length &&
                                                    banks?.map((banks, index) => (
                                                        <div  key={index}>
                                                            <div className="bg-white py-2 p-1">
                                                                <img src={banks?.picture.path} alt=""/>
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </Slider> :
                                                <>
                                                    {banks.length &&
                                                    banks?.map((banks, index) => (
                                                        <div className={'mb-2'} key={index}>
                                                            <div className="bg-white  py-2 p-1">
                                                                <img src={banks?.picture.path} alt=""/>
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            props.type === 'customers' &&
                            <div className="col-lg-3 offset-lg-2  col-md-10 col-sm-12 col-12 mt-lg-0">
                                {
                                    customers.length > 5 &&
                                    <div className="d-flex align-items-center organization-transition justify-content-md-end mb-n4 mt-3 justify-content-start">
                                        <Link onClick={()=>{previous()}}>
                                            <svg style={{transform: 'rotate(-90deg)'}} xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,28.615A14.475,14.475,0,0,1,0,14.308,14.475,14.475,0,0,1,14.607,0,14.476,14.476,0,0,1,29.213,14.308,14.476,14.476,0,0,1,14.607,28.615Zm0-26.4A12.234,12.234,0,0,0,2.263,14.308,12.234,12.234,0,0,0,14.607,26.4,12.233,12.233,0,0,0,26.95,14.308,12.234,12.234,0,0,0,14.607,2.217Z" fill="#A6926C"/><g transform="translate(7.885 10.893)"><g transform="translate(0)"><path d="M13.182,1.885,7.525,7.427a1.148,1.148,0,0,1-1.6,0L.272,1.885a1.09,1.09,0,0,1,0-1.442,1.149,1.149,0,0,1,1.6-.121L6.721,5.077,11.587.322a1.148,1.148,0,0,1,1.6,0A1.092,1.092,0,0,1,13.182,1.885Z" fill="#A6926C"/></g></g></g></svg>
                                        </Link>
                                        <Link  onClick={()=>{next()}}>
                                            <svg style={{transform: 'rotate(-90deg)'}} xmlns="http://www.w3.org/2000/svg" width="28.615" height="29.213" viewBox="0 0 28.615 29.213"><g transform="translate(28.615) rotate(90)"><path d="M14.607,0A14.475,14.475,0,0,0,0,14.308,14.475,14.475,0,0,0,14.607,28.615,14.476,14.476,0,0,0,29.213,14.308,14.476,14.476,0,0,0,14.607,0Zm0,26.4A12.234,12.234,0,0,1,2.263,14.308,12.234,12.234,0,0,1,14.607,2.217,12.233,12.233,0,0,1,26.95,14.308,12.234,12.234,0,0,1,14.607,26.4Z" fill="#A6926C"/><g transform="translate(7.885 9.973)"><path d="M13.182,5.864,7.525.322a1.148,1.148,0,0,0-1.6,0L.272,5.864a1.09,1.09,0,0,0,0,1.442,1.149,1.149,0,0,0,1.6.121L6.721,2.672l4.865,4.754a1.148,1.148,0,0,0,1.6,0A1.092,1.092,0,0,0,13.182,5.864Z" fill="#A6926C"/></g></g></svg>
                                        </Link>
                                    </div>
                                }
                                <div className="animated organizaion fadeIn">
                                    {
                                        customers.length > 5 ?
                                            <div>
                                                <Slider
                                                    className='mainslider'
                                                    ref={slider => (organization.current = slider)}
                                                    {...settings}
                                                >
                                                    {
                                                        customers.map((customer, index) => (
                                                            <div className={'box'} key={index}>
                                                                <div className="name">
                                                                    <span>{customer.administrative_region.name}</span>
                                                                    <h5 className={'line-clamp line-1'}>{customer.locale.name}</h5>
                                                                </div>
                                                                <img src={customer.cover.photo.path} alt=""/>
                                                            </div>
                                                        ))
                                                    }
                                                </Slider>
                                            </div> :
                                            <div>
                                                {
                                                    customers.map((customer, index) => (
                                                        <div className={'box mt-4'} key={index}>
                                                            <div className="name">
                                                                <span>{customer.administrative_region.name}</span>
                                                                <h5 className={'line-clamp line-1'}>{customer.locale.name}</h5>
                                                            </div>
                                                            <img src={customer.cover.photo.path} alt=""/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div>
                    <img src={Image.Map} alt="" className="bg-image"/>
                </div>

            </div>
        </div>
    );
}


const mapStateToProps = ({ banks, customers , lang }) => {
    return {  banks, customers , lang };
}

export default connect(mapStateToProps, {  getLocation, getBanks, getCustomers })(Credit);

